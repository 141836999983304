export const TourSteps = [
  {
    id: `preview`,
    popoverSide: `left`,
    title: `Preview your app`,
    content: <>
      {/* <p>
        Looks like you could be a designer after all 😉
      </p> */}

      <p>
        Making changes is easy
      </p>

      {/* <p>
        Reflow lets you toggle pretty much every features you can think of, just with a single click.
      </p> */}

      {/* <p>
        And if you get stuck, we have a lot of resources to help you out:
      </p>

      <ul>
        <li>
          <a href="https://reflow.design/support" target="_blank" rel="noreferrer">Documentation &amp; Support</a>
        </li>
        <li>
          <a href="https://reflow.design/tutorials" target="_blank" rel="noreferrer">Video series</a>
        </li>
        <li>
          <a href="https://reflow.design/contact" target="_blank" rel="noreferrer">Contact support team</a>
        </li>
      </ul> */}
    </>,
    imageSrc: `/test.png`,
    cta: `Show me how`,
    spotlight: false,
  },
  {
    id: `customize`,
    popoverSide: `bottom`,
    title: `Customize here`,
    content: <>
      <ul>
        <li>No design skill needed</li>
        <li>No dragging-and-dropping</li>
        <li>Simple yes and no questions</li>
      </ul>
    </>,
    imageSrc: `/test.png`,
    cta: `Got it`,
    spotlight: false,
  },
  {
    id: `prototype`,
    popoverSide: `bottom`,
    title: `See it live`,
    content: <>
      <p>
        Test prototypes with real users!
      </p>
    </>,
    imageSrc: `/test.png`,
    cta: `Start customizing`,
    spotlight: true,
  },
]
