const PreviewAttrCollection = ({ $large }) => {
  if ($large) {
    return <ul>
      <li>Item 1</li>
      <li>Item 2</li>
      <li>Item 3</li>
      <li>Item 4</li>
    </ul>
  }
  return <span>4 items</span>
}

export default PreviewAttrCollection
