import styled from 'styled-components/macro'

const Button = styled.button`
  text-decoration: none;
  vertical-align: top;
  border-color: transparent;
  line-height: 1;
  font-family: var(--font-family-sans);
  font-weight: 500;
  letter-spacing: 0.015rem;
  border-radius: 4px;
  cursor: ${p => p.working ? 'auto' : p.disabled ? 'auto' : 'pointer'};
  opacity: ${p => p.working ? .5 : .95};
  text-align: center;
  user-select: none;
  font-size: ${p => p.$small ? 'var(--s)' : p.$large ? 'var(--m)' : 'var(--m)'};
  position: relative;
  
  /* block */
  display: ${p => p.block ? 'block' : 'inline-block'};
  margin: ${p => p.block ? '0 auto' : 'auto' };
  width: ${p => p.$wide && '100%'};
  
  /* color */
  background: ${p => p.tertiary ? 'white' : 
                     p.$secondary ? 'var(--background-item)' : 
                     p.$destructive ? 'var(--destructive)' : 
                     'var(--brand-primary)'};
  color: ${p =>      p.tertiary && p.$destructive ? 'var(--destructive)' : 
                     p.$secondary ? 'var(--brand-primary)' : 
                     p.tertiary ? 'var(--brand-primary)' : 
                     'white'};

/* size */
  padding: ${p => p.$large ? '1rem 2rem' : p.$small ? '.5rem 1rem' : '.66rem 1.33rem'};
  padding-right: ${p => (p.$large && p.working) ? '3.5rem' :
                        (p.$small && p.working) ? '2.75rem' :
                        p.working ? '3rem' :
                        undefined };
  transition: opacity .1s ease;
  &:hover {
    opacity: ${p => p.working ? .5 : 1};
  }
  outline: none;
  &:focus {
    box-shadow: 0 0 0 2px var(--outline);
  }
  &:disabled {
    opacity: .3;
    cursor: auto;
  }
  &:before {
    display: ${p => p.working ? 'block' : 'none'};
    content: '';
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 14px;
    height: 14px;
    margin-top: -8px;
    margin-left: -8px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: var(--white);
    animation: spinner .6s linear infinite;
  }

  svg.iconify {
    display: inline-block;
    width: ${p => p.$large ? '1.4rem' : p.$small ? '1rem' : '1.2rem'};
    height: ${p => p.$large ? '1.4rem' : p.$small ? '1rem' : '1.2rem'};
    margin: ${p => p.$large ? '-.5rem .66rem -.5rem -.66rem' : p.$small ? '-.2rem .33rem -.2rem -.33rem' : '-.2rem .5rem -.2rem -.5rem'};
  }
`

export {
  Button,
}
