import {
  QuestionWrapper,
  QuestionTitle,
  QuestionDescription,
  Example,
  QuestionError,
  Badge,
} from '../../styles/Questions'
import { Input } from '../../styles/Forms'

const QuestionBigText = ({
  register,
  question,
  errors,
  context,
}) => {

  const { id, title, description, example, comingSoon } = question

  return <QuestionWrapper>
    <QuestionTitle>
      { title(context) }
      { comingSoon && <Badge>Coming soon</Badge>}
    </QuestionTitle>
    {description && <QuestionDescription>{ description(context) }</QuestionDescription> }
    {example && <Example>{ example }</Example> }
    <Input type="text" name={id} disabled={comingSoon} {...register(id)} />
    {errors[id] && <QuestionError>This field is required</QuestionError>}
  </QuestionWrapper>
}

export default QuestionBigText
