// match keys here to formData/questions ids

export const CollectionDefaults = [
  {
    id: `people`,
    collection_name_plural: `People`,
    collection_icon: `account-multiple`,
    displayTest: () => false,
  },
  {
    id: `places`,
    collection_name_plural: `Places`,
    collection_icon: `map-marker`,
    displayTest: context => context.app_type?.includes('social'),
  },
  {
    id: `events`,
    collection_name_plural: `Events`,
    collection_icon: `calendar`,
    displayTest: context => context.app_type?.includes('events'),
  },
  {
    id: `documents`,
    collection_name_plural: `Documents`,
    collection_icon: `file-document-outline`,
    displayTest: context => context.app_type?.includes('documents'),
  },
  {
    id: `tasks`,
    collection_name_plural: `Tasks`,
    collection_icon: `checkbox-marked-circle-outline`,
    displayTest: context => context.app_type?.includes('tasks'),
  },
  {
    id: `products`,
    collection_name_plural: `Products`,
    collection_icon: `dollar`,
    displayTest: context => context.app_type?.includes('ecommerce'),
  },
  {
    id: `media`,
    collection_name_plural: `Media`,
    collection_icon: `play-box-multiple-outline`,
    displayTest: context => context.app_type?.includes('media'),
  },
  {
    id: `things`,
    collection_name_plural: `Items`,
    collection_icon: `shape`,
    displayTest: context => context.app_type?.includes('other'),
  },
]
