import styled from 'styled-components/macro'
import { Controller } from 'react-hook-form'

import {
  QuestionWrapper,
  QuestionTitle,
  QuestionDescription,
  Example,
  Consequences,
  Badge,
} from '../../styles/Questions'
import {
  SwitchRoot,
  SwitchThumb,
} from '../../components/Switch'

const QuestionToggle = ({
  question,
  formData,
  control,
  context,
}) => {

  const { id, title, description, example, choices, comingSoon } = question

  const falseValue = choices[0].id
  const trueValue = choices[1].id
  const questionAnswer = formData[id]
  const chosenAnswer = choices && choices.find(c => c.id === questionAnswer)

  return <QuestionWrapper>
    <TwoCols>
      <div>
        <QuestionTitle>
          { title(context) }
          { comingSoon && <Badge>Coming soon</Badge>}
        </QuestionTitle>
        {description && <QuestionDescription>{ description(context) }</QuestionDescription> }
      </div>
      <div>
        {/* https://react-hook-form.com/api/usecontroller/controller */}
        <Controller
          name={id}
          control={control}
          render={({ field: { onChange, value, name } }) => {
            return <SwitchRoot
              name={name}
              checked={comingSoon ? false : value === trueValue}
              disabled={comingSoon}
              onCheckedChange={value => onChange(value ? trueValue : falseValue)}
            >
              <SwitchThumb />
            </SwitchRoot>
          }}
        />
      </div>
    </TwoCols>
    {/* These live outside the two-col layout so the toggle button (align-center) doesn't shift down when they appear */}
    {example && <Example>{ example }</Example> }
    {chosenAnswer && chosenAnswer.consequence && <Consequences>{ chosenAnswer.consequence }</Consequences> }
  </QuestionWrapper>
}

const TwoCols = styled.label`
  display: grid;
  grid-template-columns: auto max-content;
  grid-column-gap: 1rem;
  align-items: center;

  cursor: pointer;
`

export default QuestionToggle
