import PreviewAttrCollection from '../views/previews/attributes/PreviewAttrCollection'
import PreviewAttrUser from '../views/previews/attributes/PreviewAttrUser'
import PreviewAttrToggle from '../views/previews/attributes/PreviewAttrToggle'
import PreviewAttrChoice from '../views/previews/attributes/PreviewAttrChoice'
import PreviewAttrDate from '../views/previews/attributes/PreviewAttrDate'
import PreviewAttrShortText from '../views/previews/attributes/PreviewAttrShortText'
import PreviewAttrLongText from '../views/previews/attributes/PreviewAttrLongText'
import PreviewAttrRichText from '../views/previews/attributes/PreviewAttrRichText'
import PreviewAttrLocation from '../views/previews/attributes/PreviewAttrLocation'
import PreviewAttrImages from '../views/previews/attributes/PreviewAttrImages'
import PreviewAttrTags from '../views/previews/attributes/PreviewAttrTags'
import PreviewAttrStatus from '../views/previews/attributes/PreviewAttrStatus'
import PreviewAttrCategory from '../views/previews/attributes/PreviewAttrCategory'
import PreviewAttrFile from '../views/previews/attributes/PreviewAttrFile'
import PreviewAttrCode from '../views/previews/attributes/PreviewAttrCode'
import PreviewAttrUnknown from '../views/previews/attributes/PreviewAttrUnknown'

const AttributeTypes = [
  {
    id: `attr_type_collection`,
    title: `Collection`,
    description: `Link another data type to this data`,
    PreviewComponent: PreviewAttrCollection,
  },
  {
    id: `attr_type_user`,
    title: `User`,
    description: `Link to another user of the app`,
    PreviewComponent: PreviewAttrUser,
  },
  {
    id: `attr_type_toggle`,
    title: `Toggle`,
    description: `For data that is either on or off; true or false`,
    PreviewComponent: PreviewAttrToggle,
  },
  {
    id: `attr_type_choice`,
    title: `Choice`,
    description: `Choosing data from a list`,
    PreviewComponent: PreviewAttrChoice,
  },
  {
    id: `attr_type_date`,
    title: `Date`,
    description: `Good for setting a date`,
    PreviewComponent: PreviewAttrDate,
  },
  {
    id: `attr_type_short_text`,
    title: `Short text`,
    description: `Good for names`,
    PreviewComponent: PreviewAttrShortText,
  },
  {
    id: `attr_type_long_text`,
    title: `Long text`,
    description: `Good for long descriptions or article content`,
    PreviewComponent: PreviewAttrLongText,
  },
  {
    id: `attr_type_rich_text`,
    title: `Rich text`,
    description: `Similar to long text, but lets users format text, add images etc`,
    PreviewComponent: PreviewAttrRichText,
  },
  {
    id: `attr_type_location`,
    title: `Location`,
    description: `An address, area, or point on the map`,
    PreviewComponent: PreviewAttrLocation,
  },
  {
    id: `attr_type_images`,
    title: `Images`,
    description: `Pictures you can upload`,
    PreviewComponent: PreviewAttrImages,
  },
  {
    id: `attr_type_tags`,
    title: `Tags`,
    description: `A list of tags`,
    PreviewComponent: PreviewAttrTags,
  },
  {
    id: `attr_type_status`,
    title: `Status`,
    description: `The state something is in, ie "published" or "unpublished"`,
    PreviewComponent: PreviewAttrStatus,
  },
  {
    id: `attr_type_category`,
    title: `Category`,
    description: `A category like "Fruits" or "Dogs"`,
    PreviewComponent: PreviewAttrCategory,
  },
  {
    id: `attr_type_file`,
    title: `File`,
    description: `A file they upload`,
    PreviewComponent: PreviewAttrFile,
  },
  {
    id: `attr_type_code`,
    title: `Code`,
    description: `Users will be able to add custom computer code. Useful for letting end users customize blog posts etc.`,
    PreviewComponent: PreviewAttrCode,
  },
  {
    id: `attr_type_unknown`,
    title: `Other`,
    description: `Empty`,
    PreviewComponent: PreviewAttrUnknown,
  },
]

export default AttributeTypes
