import { useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import produce from 'immer'

import { 
  SortableListWrapper,
  Group,
  Adder,
} from '../../styles/Sortables'
import {
  Dropdown,
  DropdownTrigger,
  DropdownContent,
  // DropdownLabel,
  DropdownItem,
  // DropdownGroup,
  // DropdownCheckbox,
  // DropdownIndicator,
  // DropdownRadioGroup,
  // DropdownRadioItem,
  DropdownSeparator,
  // DropdownArrow,
} from '../../components/DropdownMenu'
import {
  DialogRoot,
} from '../../components/Dialog'
import ListBuilderItem from './components/ListBuilderItem'
import ListBuilderModal from './components/ListBuilderModal'
import {
  QuestionWrapper,
  QuestionTitle,
  QuestionDescription,
  Example,
} from '../../styles/Questions'

// https://egghead.io/lessons/react-move-items-between-columns-with-react-beautiful-dnd-using-ondragend
// https://github.com/immerjs/immer

const QuestionBuilder = ({
  question,
  formData,
  saveFormData,
  context,
  isDataPage,
  currentCollectionId,
}) => {

  const { id, title, description, example, suggestions, EditorForm } = question

  const [ customIsOpen, setCustomIsOpen ] = useState(false)

  // TODO - pass this in from question data
  const itemType = id === 'item_attributes' ? 'attribute' : id === 'item_actions' ? 'action' : 'item'

  let allAnswers
  if (!isDataPage) {
    allAnswers = formData[id] || []
  } else {
    const collectionIndex = formData.collections.findIndex(c => c.id === currentCollectionId)
    allAnswers = (collectionIndex > -1 && formData.collections && formData.collections[collectionIndex] && formData.collections[collectionIndex][id]) ? formData.collections[collectionIndex][id] : []
  }

  const arrayOfAnswerIds = allAnswers.map(ag => ag.items).flat().map(a => a.id)
  const filteredSuggestions = suggestions && suggestions.filter(s => !arrayOfAnswerIds.includes(s.id))

  const onDragEnd = ({ source, destination }) => {
    
    // dropped outside a list
    if (!destination) {
      return
    }

    // source and destination may be the same (still works fine)
    
    const newData = produce(formData, formDataDraft => {
      let questionData
      if (!isDataPage) {
        questionData = formDataDraft[id]
      } else {
        const collectionIndex = formDataDraft.collections.findIndex(c => c.id === currentCollectionId)
        questionData = formDataDraft.collections[collectionIndex][id]
      }
      const sourceGroup = questionData.find(group => group.id === source.droppableId)
      const destinationGroup = questionData.find(group => group.id === destination.droppableId)
      const sourceAnswers = sourceGroup.items || []
      const destinationAnswers = destinationGroup.items || []
      const [ removed ] = sourceAnswers.splice(source.index, 1)
      destinationAnswers.splice(destination.index, 0, removed)
    })
    saveFormData(newData)

  }

  const addItem = (itemId, groupId) => {
    if (itemId === '_nothing') {
      return
    } else if (itemId === '_custom') {
      alert(`TODO - form to add a custom ${itemType}`)
    } else {
      const newOne = suggestions.find(s => s.id === itemId)
      const newData = produce(formData, formDataDraft => {
        let questionData
        if (!isDataPage) {
          questionData = formDataDraft[id]
        } else {
          const collectionIndex = formDataDraft.collections.findIndex(c => c.id === currentCollectionId)
          questionData = formDataDraft.collections[collectionIndex][id]
        }
        const group = questionData.find(group => group.id === groupId)
        group.items.push(newOne)
      })
      saveFormData(newData)
    }
  }

  const removeBuilderItem = (indexToRemove, groupId) => {
    console.log('remove', indexToRemove)
    const newData = produce(formData, formDataDraft => {
      let questionData
      if (!isDataPage) {
        questionData = formDataDraft[id]
      } else {
        const collectionIndex = formDataDraft.collections.findIndex(c => c.id === currentCollectionId)
        questionData = formDataDraft.collections[collectionIndex][id]
      }
      const group = questionData.find(group => group.id === groupId)
      group.items.splice(indexToRemove, 1)
    })
    saveFormData(newData)
  }

  return <QuestionWrapper>
    {title && <h3>{ title(context) }</h3>}
    {description && <QuestionDescription>{ description(context) }</QuestionDescription> }
    {example && <Example>{ example }</Example> }

    <DragDropContext onDragEnd={onDragEnd}>
      {allAnswers && allAnswers.map(answerGroup => {
        return <Group key={answerGroup.id}>
          {allAnswers.length > 1 && <QuestionTitle>{answerGroup.title}</QuestionTitle> }
          <Droppable droppableId={answerGroup.id}>
            {({ droppableProps, innerRef, placeholder }, snapshot) => (
              <SortableListWrapper {...droppableProps} ref={innerRef} isDraggingOver={snapshot.isDraggingOver}>
                {answerGroup.items && answerGroup.items.map( (item, index) => {
                  return <ListBuilderItem
                    key={item.id}
                    item={item}
                    index={index}
                    formData={formData}
                    saveFormData={saveFormData}
                    itemType={itemType}
                    removeItem={() => removeBuilderItem(index, answerGroup.id)}
                    EditorForm={EditorForm}
                    currentCollectionId={currentCollectionId}
                    isDataPage={isDataPage}
                    question={question}
                    answerGroupId={answerGroup.id}
                  />
                })}
                {placeholder}
              </SortableListWrapper>
            )}
          </Droppable>

          <Dropdown>
            <DropdownTrigger asChild={true}>
              <Adder>
                Add an {itemType}...
              </Adder>
            </DropdownTrigger>

            <DropdownContent sideOffset={4}>
              {filteredSuggestions && filteredSuggestions.map(suggestion => {
                return <DropdownItem key={suggestion.id} onSelect={() => addItem(suggestion.id, answerGroup.id)}>
                  {suggestion.title}
                </DropdownItem>
              })}
              { filteredSuggestions && filteredSuggestions.length > 0 && <DropdownSeparator /> }
              <DropdownItem key="custom_attribute" onSelect={() => setCustomIsOpen(true)}>Custom attribute</DropdownItem>
            </DropdownContent>
          </Dropdown>

        </Group>
      })}
    </DragDropContext>
    
    <DialogRoot
      id={'_custom'}
      open={customIsOpen}
      onOpenChange={() => setCustomIsOpen(!customIsOpen)}
      modal={true}
    >
      <ListBuilderModal
        itemType={itemType}
        formData={formData}
        saveFormData={saveFormData}
        close={() => setCustomIsOpen(false)}
        EditorForm={EditorForm}
        currentCollectionId={currentCollectionId}
        isDataPage={isDataPage}
        question={question}
        answerGroupId={allAnswers && allAnswers[0]?.id} // todo - not actually working because this is not inside the group
      />
    </DialogRoot>

  </QuestionWrapper>
}

export default QuestionBuilder
