import { useEffect, useState, useContext } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { customAlphabet } from 'nanoid/non-secure'
import { object } from 'yup'

import { AppContext } from '../App'
import Questions from '../../data/questions'
import { CollectionDefaults } from '../previews/data/collection_defaults'
// import chevronLeft from '../../svg/ui/chevron-left.svg'
import Pages from '../../data/pages'
import PanelNav from './PanelNav'
import useWindowSize from '../../hooks/useWindowSize'
import Form from '../Form'
// import Errors from './Errors'
import PanelPreview from './PanelPreview'
import { Button } from '../../styles/Buttons'
import TourPoint from '../../components/TourPoint'
import { TourSteps } from '../../data/tourSteps'

const nanoid = customAlphabet('1234567890abcdef', 5)

const Panel = () => {

  const {
    formData,
    saveFormData,
    setIsPurchasing,
  } = useContext(AppContext)

  const [ menuIsOpen, setMenuIsOpen ] = useState(false)

  // manage pages
  const { currentPageId, currentCollectionId } = useParams()
  const pages = Pages.filter(p => !p.inWizard)
  const currentPage = pages.find(p => p.id === currentPageId)
  const isDataPage = currentPage && currentPage.dataPage
  
  // onboarding
  // visibility is managed by state; any valid index shows a step, otherwise no step
  const hasProbablySeenTour = localStorage.getItem('reflow-has-finished-onboarding-tour')
  const [ currentOnboardingStepIndex, setCurrentOnboardingStepIndex ] = useState(!hasProbablySeenTour && 0)
  const nextTourStep = () => {
    localStorage.setItem('reflow-has-finished-onboarding-tour', true)
    setCurrentOnboardingStepIndex(currentOnboardingStepIndex + 1)
  }
  const skipTour = () => {
    localStorage.setItem('reflow-has-finished-onboarding-tour', true)
    setCurrentOnboardingStepIndex(9999)
  }

  // manage mobile sizes
  const size = useWindowSize()
  const MOBILE_BREAKPOINT = 600
  const isMobile = size.width < MOBILE_BREAKPOINT

  // ---------------------------------------
  //      MANUALLY WATCH FOR ERRORS
  // ---------------------------------------
  const [ manualErrors, setManualErrors ] = useState([])

  // once errors pages have been loaded, we start showing error count in the nav
  // and we should start checking for error resolution whenever form data is updated
  // this is a one-way flag that saves whether or not error pages have been viewed
  const [ isCheckingForErrorsTripwire, setIsCheckingForErrorsTripwire ] = useState(false)

  // https://github.com/jquense/yup/issues/44
  // https://stackoverflow.com/a/56492329
  useEffect(() => {
    // why
    if (!currentPage) {
      return
    }

    if (!isCheckingForErrorsTripwire && currentPage.inWizard) {
      return
    }

    setIsCheckingForErrorsTripwire(true)

    const yupErrorValidations = Questions.reduce((result, question) => {
      if (!question.yup_validation) {
        return result
      }
      return {
        ...result,
        [question.id]: question.yup_validation,
      }
    }, {})
    const errorSchema = object(yupErrorValidations)
    return errorSchema.validate(formData, { abortEarly: false })
      .then( () => {
        setManualErrors([])
      })
      .catch(errors => {
        // https://github.com/jquense/yup/issues/44#issuecomment-761780927
        setManualErrors(errors.inner.map(e => {
          return {
            questionId: e.path,
            message: e.message,
          }
        }))
      })
  }, [formData, isCheckingForErrorsTripwire, currentPageId, currentPage])


  // ---------------------------------------
  //      GENERATE FORM DATA ON LOAD
  // ---------------------------------------
  // this should happen once the wizard is done and we have enough information to set defaults on all form elements
  useEffect( () => {

    // generate a list of collections
    const defaultCollections = CollectionDefaults.filter(cd => cd.id === 'things')
    const wizardCollections = CollectionDefaults.filter(cd => cd.displayTest(formData))
    // debugger
    const cleanedCollections = (wizardCollections.length > 0 ? wizardCollections : defaultCollections).map(c => {
      const { displayTest, ...rest } = c
      return rest
    })
    const collections = cleanedCollections.map(c => ({
      ...c,
      id: nanoid(),
    }))

    // generate default values for whole form
    // TODO - dedupe from Form.js?
    const defaultFormData = Questions.reduce( (result, question) => {

      const page = Pages.find(p => p.id === question.page)

      if (!page) {
        console.error(`Could not set defaults for question ${question.id} because it's parent page ${question.page} was not found in pages`)
      }

      // generate all "root" questions
      if (!page.dataPage) {

        let questionAnswer
        if (question.default) {
          questionAnswer = question.default(formData)
        }
        if (formData[question.id]) {
          questionAnswer = formData[question.id]
        }

        if (!questionAnswer) {
          return result
        }

        return {
          ...result,
          [question.id]: questionAnswer,
        }
      }

      // if this question is a data question, set it for every collection
      result.collections.map( collection => {

        let questionAnswer
        if (question.default) {
          questionAnswer = question.default(formData)
        }
        if (collection[question]) {
          questionAnswer = collection[question]
        }

        const collectionIndex = formData.collections.findIndex(c => c.id === collection.id)
        if (collectionIndex >= 0) {
          if (formData.collections[collectionIndex][question.id]) {
            questionAnswer = formData.collections[collectionIndex][question.id]
          }
        }

        if (!questionAnswer) {
          return collection
        }
        return {
          ...collection,
          [question.id]: questionAnswer,
        }
      })
  
      return result
  
    }, { collections }) // start with collections  

    // save initial form data for all non-wizard fields
    saveFormData({
      ...defaultFormData,
    })

  }, []) //eslint-disable-line

  // -----------------------------------------

  const nav = <PanelNav
    pages={pages}
    currentPage={currentPage}
    closeOnClick={isMobile}
    setMenuIsOpen={setMenuIsOpen}
    formData={formData}
    saveFormData={saveFormData}
    setIsPurchasing={setIsPurchasing}
  />

  // TODO - test this again
  // TODO - dedupe this from Router?
  const collectionIds = formData.collections.map(c => c.id)
  const firstNonWizardNonDataPage = Pages.filter(p => !p.inWizard && !p.dataPage)[0]
  const missingCollection = currentCollectionId && !collectionIds.includes(currentCollectionId)
  if ( missingCollection ) {
    return <Navigate to={`/build/${firstNonWizardNonDataPage.id}`} />
  }

  return <PanelWrapper>

    <PanelHeader>
      <PanelHeaderLogo>
        {isMobile ?
          <>
            <div>
              <HamburgerIcon onClick={() => setMenuIsOpen(!menuIsOpen)}>
                <svg style={{ width:'24px', height:'24px' }} viewBox="0 0 24 24">
                  <path fill="currentColor" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
                </svg>
              </HamburgerIcon>
              { menuIsOpen && <Menu>{nav}</Menu> }
            </div>
            <div>{currentPage.title}</div>
          </>
        :
          <NameWrapper>
            {/* 
              TODO - bring back "back" arrow once we have a dashboard list of projects persisted
              also warn them before leaving if we don't require email here and start auto-saving
              https://dev.to/chromiumdev/sure-you-want-to-leavebrowser-beforeunload-event-4eg5
            */}
            {/* <BackArrow to="/">
              <img alt="back" src={chevronLeft} />
            </BackArrow> */}
            <div>{formData.app_name || 'Untitled'}</div>
          </NameWrapper>
        }
      </PanelHeaderLogo>
      <PanelHeaderRight>
        {/* TODO - bring errors back once they're accurate with data pages */}
        {/* <Errors
          manualErrors={manualErrors}
        /> */}

        <TourPoint
          stepId={TourSteps[2].id}
          currentOnboardingStepIndex={currentOnboardingStepIndex}
          nextTourStep={nextTourStep}
          skipTour={skipTour}
        >
          <Button
            as={Link}
            to="/prototype"
            // TODO - open in new tab once the current tab doesn't hold all our state
            // target="_blank"
            // rel="noopener noreferrer"
          >
            <span className="iconify" data-icon="mdi-play" />
            Preview
          </Button>
        </TourPoint>

      </PanelHeaderRight>
    </PanelHeader>
    
    <PanelBody>
      {!isMobile && <PanelNavWrapper>{nav}</PanelNavWrapper>}

      <PanelFormWrapper>
        <TourPoint
          stepId={TourSteps[1].id}
          currentOnboardingStepIndex={currentOnboardingStepIndex}
          nextTourStep={nextTourStep}
          skipTour={skipTour}
        >
          <Form
            key={currentCollectionId || currentPageId} /* make sure this is different for each form so it rerenders fresh */
            currentPage={currentPage}
            currentPageId={currentPageId}
            saveFormData={saveFormData}
            formData={formData}
            manualErrors={manualErrors}
            currentCollectionId={currentCollectionId}
            isDataPage={isDataPage}
          />
        </TourPoint>
      </PanelFormWrapper>

      <TourPoint
        stepId={TourSteps[0].id}
        currentOnboardingStepIndex={currentOnboardingStepIndex}
        nextTourStep={nextTourStep}
        skipTour={skipTour}
      >
        <PanelPreview
          formData={formData}
          currentPage={currentPage}
          currentCollectionId={currentCollectionId}
          isDataPage={isDataPage}
        />
      </TourPoint>

    </PanelBody>
  </PanelWrapper>
}

const PanelWrapper = styled.div`
  display: grid;
  // https://css-tricks.com/preventing-a-grid-blowout/
  grid-template-rows: max-content minmax(0, 1fr);
  height: 100vh;
`
const PanelHeader = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: 1rem;
  align-items: center;
  padding: .666rem 1rem;
  border-bottom: 1px solid var(--hairline);
`
const PanelHeaderLogo = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 1rem;
  align-items: center;
  margin: 0 0 0 0.5rem;
`
const PanelHeaderRight = styled.div`
  display: flex;
  align-items: center;
`
const PanelBody = styled.div`
  display: grid;
  grid-template-columns: 200px 3fr 3fr;

  @media(max-width: 600px) {
    grid-template-columns: auto;
    grid-template-rows: 200px auto; /* height of preview wrapper */
  }
`
const PanelNavWrapper = styled.div`
  padding: 1rem 0;
  border-right: 1px solid var(--hairline);
  min-height: 0;
  `
const PanelFormWrapper = styled.div`
  padding: 2rem;
  overflow-y: auto;
  min-height: 0;
  overflow: auto;
  /* max-width: 600px; */

  @media(max-width: 600px) {
    order: 1;
    overflow-y: initial;
  }
`
const Menu = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: white;
  top: 0;
  left: 0;
  padding: 6rem .5rem 2rem;
  background: white;
  z-index: 100;
`
const HamburgerIcon = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  z-index: 200;
  position: relative;
  cursor: pointer;
`
const NameWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: max-content max-content;
`
// const BackArrow = styled(Link)`
//   opacity: .4;
//   cursor: pointer;
//   padding: .25rem .5rem .25rem 0;

//   img {
//     width: 20px;
//     height: 20px;
//     display: block;
//   }
//   &:hover {
//     opacity: .6;
//   }
// `
// const PanelPreviewWrapper = styled.div`
//   min-height: 0;
// `

export default Panel
