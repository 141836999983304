import ArrowDown from '../../svg/previews/arrow_down.svg'
import {
  PageFlowWrapper,
  PageFlowArrow,
  PageFlowPoint,
  EmailWrapper,
  EmailSubject,
  EmailBody,
} from '../../styles/Previews'
import { DripEmails } from './data/dripEmails'

const PreviewEmail = ({ context }) => {
  const {
    email_types,
  } = context

  if (!email_types || email_types.length < 1) {
    return '(no drip emails)'
  }

  return <PageFlowWrapper>
    <PageFlowPoint key="signup">Sign up</PageFlowPoint>
    {DripEmails.map( email => {
      const { id, subject, body } = email
      if (!email_types.includes(id)) {
        return null
      }
      return <div key={id} style={{display: 'grid', justifyItems: 'center'}}>
        <PageFlowArrow src={ArrowDown} />
        <EmailWrapper>
          <EmailSubject>{subject(context)}</EmailSubject>
          <EmailBody>{body(context)}</EmailBody>
        </EmailWrapper>
      </div>
    })}
  </PageFlowWrapper>
}

export default PreviewEmail
