import styled from 'styled-components/macro'
import { useState } from 'react'

import {
  Example,
  QuestionDescription,
} from '../../styles/Questions'

const QuestionIntro = ({
  question,
  context,
}) => {

  const { title, description, example, youtubeId } = question

  const [ videoOpen, setVideoOpen ] = useState(false)

  const toggleVideo = event => {
    event.preventDefault()
    setVideoOpen(!videoOpen)
  }

  return <QuestionIntroWrapper>
    <h3>{ title(context) }</h3>
    {description && <QuestionDescription>{ description(context) }</QuestionDescription> }
    {example && <Example>{ example }</Example> }
    {youtubeId && !videoOpen && <VideoLink onClick={toggleVideo}>
      <span className="iconify" data-icon="mdi-play-circle-outline" />
      Play video
    </VideoLink> }
    {youtubeId && videoOpen && <Iframe width={560} height={315} src={`https://www.youtube.com/embed/${youtubeId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></Iframe> }
  </QuestionIntroWrapper>
}

const QuestionIntroWrapper = styled.div`
  padding: 0 0 1rem;
  border-bottom: 1px solid var(--hairline);
  margin: 0 0 2rem;
`
const VideoLink = styled.div`
  color: var(--brand-primary);
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height: 1;
  cursor: pointer;
  opacity: .8;
  margin: 1rem 0 0;

  &:hover {
    opacity: 1;
  }
  svg {
    display: inline-block;
    margin: 0 .25rem 0 0;
    width: 20px;
    height: 20px;
  }
`
const Iframe = styled.iframe`
  margin: 1rem 0 0;
  background: var(--background-item);
  max-width: 100%;
`

export default QuestionIntro
