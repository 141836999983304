import NavSecondary from './components/NavSecondary'
import NavMenu from './components/NavMenu'
import {
  AppNav,
} from '../../styles/Previews'

const PreviewNavOther = ({ context }) => {
  return <AppNav>
    <div />
    <NavSecondary context={context} />
    <NavMenu context={context} />
  </AppNav>
}

export default PreviewNavOther
