import styled from 'styled-components/macro'

// app

export const AppBody = styled.div`
  display: grid;
  grid-template-columns: ${p => p => p.theme.navType === 'horizontal' ? 'auto' : 'max-content auto'};
  grid-template-rows: ${p => p.theme.navType === 'horizontal' ? 'max-content auto' : 'auto'};
  width: ${p => p.theme.standalone ? 'auto' : '800px'};
  box-shadow: var(--shadow-elevation-medium);
`
export const AppMain = styled.div`
  background: white;
  padding: 2rem;
  background: ${p => p.theme.bodyBg};
`

// NAV

export const AppNav = styled.div`
  position: relative;

  display: flex;
  flex-direction: ${p => p.theme.navType === 'horizontal' ? 'row' : 'column'};
  align-content: ${p => p.theme.navType === 'horizontal' ? 'center' : null};
  align-items: ${p => p.theme.navType === 'horizontal' ? 'center' : null};
  justify-content: space-between;

  width: ${p => p.theme.navType === 'horizontal' ? '100%' : '200px'};
  min-width: ${p => p.theme.navType === 'horizontal' ? '800px' : 'auto'};
  height: ${p => p.theme.navType === 'horizontal' ? '54px' : 'auto'};
  min-height: ${p => p.theme.navType === 'horizontal' ? 'auto' : '600px'};
  padding: ${p => p.theme.navType === 'horizontal' ? '.5rem 1rem' : '.5rem'};
  border-width: ${p => p.theme.navType === 'horizontal' ? `0 0 1px 0` : `0 1px 0 0`};
  border-color: ${p => p.theme.hairline};
  border-style: ${p => p.theme.boldColor ? 'none' : 'solid' };

  background: ${p => p.theme.boldColor ? p.theme.backgroundBrand : 'white' };
  color: ${p => p.theme.boldColor ? 'white' : p.theme.textPrimary };
  font-size: var(--s);
`
export const AppNavMain = styled.div`
  display: flex;
  flex-direction: ${p => p.theme.navType === 'horizontal' ? 'row' : 'column'};
  align-content: ${p => p.theme.navType === 'horizontal' ? 'center' : null};
`
export const AppNavSecondaryWrapper = styled.div`
  display: flex;
  flex-direction: ${p => p.theme.navType === 'horizontal' ? 'row' : 'column'};
  align-content: ${p => p.theme.navType === 'horizontal' ? 'center' : null};
  align-items: ${p => p.theme.navType === 'horizontal' ? 'center' : null};
`
export const NavLogo = styled.div`
  padding: .5rem;
  font-weight: bold;
  color: ${p => p.theme.boldColor ? 'white' : p.theme.textBrand };
  margin: ${p => p.theme.navType === 'horizontal' ? '0' : '0 0 0.5rem'};
`
export const NavItem = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: ${p => p.theme.navType === 'horizontal' ? '0' : '.5rem'};
  align-items: center;
  padding: .5rem;
  border-radius: 3px;
  color: ${p => p.theme.boldColor ? p.theme.textContrast : p.theme.textSecondary };
  font-size: var(--s);
  font-weight: 500;

  &.active {
    background: ${p => p.theme.navType === 'horizontal' ? 'transparent' : p.theme.boldColor ? p.theme.darkBrandColor : p.theme.lightBrand};
    color: ${p => p.theme.boldColor ? 'white' : p.theme.textBrand };
    font-weight: 500;
  }
`
export const NavText = styled.div`
`
export const NavIcon = styled.div`
  svg {
    display: block;
    width: 18px;
    height: 18px;
  }
`
export const SearchInput = styled.input`
  background: white;
  border-radius: 100px;
  font-size: var(--xs);
  line-height: 1;
  padding: .4rem 1rem;
  border: ${p => p.theme.boldColor ? 'none' : `1px solid ${p.theme.hairline}`};
  pointer-events: none;
  margin: 0;

  &::placeholder {
    color: ${p => p.theme.textTertiary};
  }
`
export const NavAccount = styled.div`
  padding: ${p => p.theme.navType === 'horizontal' ? 'none' : '1rem .5rem .5rem'};
  display: grid;
  grid-gap: .5rem;
  grid-template-columns: max-content auto;
  align-items: center;
  margin: ${p => p.theme.navType === 'horizontal' ? 'none' : '0.5rem 0 0'};
`
export const NavAccountPopup = styled.div`
  width: 180px;
  border-radius: 10px;
  background: white;
  box-shadow: var(--shadow-elevation-medium);
  color: ${p => p.theme.textPrimary};
  padding: 1rem;
  
  position: ${p => p.solo ? 'relative' : 'absolute'};
  right:    ${p => p.solo ? '0' : p.theme.navType === 'horizontal' ? '10px' : '-160px'};
  bottom:   ${p => p.solo ? '0' : p.theme.navType === 'horizontal' ? 'auto' : '20px'};
  top:      ${p => p.solo ? '0' : p.theme.navType === 'horizontal' ? '40px' : 'auto'};
`
export const NavAccountPopupPerson = styled.div`
  text-align: center;
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: .5rem;
  justify-content: center;
  padding: 0.5rem 0 1rem;
  justify-items: center;
  border-bottom: 1px solid ${p => p.theme.hairline};
`
export const PopupAvatar = styled.img`
  display: block;
  width: ${p => p.size ? `${p.size}px` : `40px`};
  height: ${p => p.size ? `${p.size}px` : `40px`};
  border-radius: 100px;
  background: #eee;
`

// page

export const FloatingPage = styled.div`
  background: ${p => p.theme.bodyBg};
  box-shadow: var(--shadow-elevation-medium);
  width: 500px;
  border-radius: 4px;
  color: ${p => p.theme.textPrimary};
  padding: 1.5rem;
`
export const PageHeader = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  margin: -.5rem 0 1.5rem;
  padding: 0 0 .5rem 0;
  border-bottom: 1px solid ${p => p.theme.hairline};
`
export const ItemSummary = styled.div`
  margin: 0.5rem 0 0rem 0;
`
export const PageHeaderGlobal = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: .5rem;
`
export const Breadcrumbs = styled.div`
  margin: 0 0 .0025rem;
  color: ${p => p.theme.textTertiary};
  font-size: 11px;
`
export const PageTitle = styled.div`
  font-size: var(--l);
  font-weight: 600;
  font-family: ${p => p.theme.fontHeading || 'inherit'};
`
export const ContentBox = styled.div`
  width: 100%;
  height: ${p => p.$small ? '20px' : '60px'};
  box-shadow: ${p => p.theme.cardShadow };
  background: white;
  border-radius: 10px;
  margin: 0 0 .5rem;
`

// onboarding

export const ModalBg = styled.div`
  // TODO
`
export const ModalContent = styled.div`
  background: ${p => p.theme.bodyBg};
  box-shadow: var(--shadow-elevation-medium);
  width: 500px;
  border-radius: 4px;
  color: ${p => p.theme.textPrimary};
  padding: 1.5rem;
`
export const ModalTitle = styled.div`
  font-size: var(--l);
  font-weight: 600;
  margin: 0 0 1rem;
`

// onboarding

export const OnboardingPage = styled.div`
  background: white;
  box-shadow: var(--shadow-elevation-medium);
  width: 500px;
  padding: 4rem;
  border-radius: 10px;
  color: ${p => p.theme.textBrand};
`
export const OnboardingHeading = styled.div`
  font-family: ${p => p.theme.fontHeading || 'inherit'};
  color: ${p => p.theme.textBrand};
  font-weight: bold;
  font-size: var(--xl);
  margin: 0 0 1rem;
  text-align: center;
`
export const OnboardingCopy = styled.div`
  font-size: var(--m);
  text-align: center;
`


// PAGE FLOW
export const PageFlowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const PageFlowArrow = styled.img`
  width: 60px;
  height: 60px;
`
export const PageFlowPoint = styled.div`
  padding: .33em 1em;
  border-radius: 100px;
  font-weight: 500;
  background: var(--background-item-dark);
  /* font-size: var(--s); */
  /* background: white; */
  /* box-shadow: var(--shadow-elevation-low); */
`

// EMAIL
export const EmailWrapper = styled.div`
  position: relative;
  
  width: 300px;
  min-height: 100px;
  background: white;
  padding: 1.5rem;
  /* text-align: center; */
  border-radius: 10px;
  box-shadow: var(--shadow-elevation-medium);
  
  /* -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  mask-image:         linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); */
`
export const EmailSubject = styled.div`
  border-bottom: 1px solid ${p => p.theme.hairline};
  font-weight: 500;
  color: ${p => p.theme.textPrimary};
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
`
export const EmailBody = styled.div`
  color: ${p => p.theme.textSecondary};
`

// MISC
export const Button = styled.div`
  margin: 0 0 .5rem;
  width: ${p => p.block && '100%'};

  font-size: ${p => p.$small ? 'var(--s)' : p.$large ? 'var(--l)' : 'var(--m)'};
  padding: ${p => p.$small ? '.5em 1em' : p.$large ? '.75em 1em' : '.5em 1em'};

  text-align: center;
  border-radius: ${p => p.theme.buttonRadius || '4px'};
  font-weight: 500;

  background: ${p =>  p.$secondary ? 'white' : p.background || p.theme.buttonBg};
  color: ${p => p.$secondary ? p.theme.buttonBg : p.theme.buttonText};
  box-shadow: ${p => p.$secondary && `inset 0 0 0 1px ${p.theme.buttonBg}`};

  &:last-child {
    /* margin-bottom: 0; */
  }
`
