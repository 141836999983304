import PreviewActions from              '../views/previews/PreviewActions'
// import PreviewAppName from              '../views/previews/PreviewAppName'
import PreviewAuth from                 '../views/previews/PreviewAuth'
import PreviewCalendar from             '../views/previews/PreviewCalendar'
import PreviewDashboard from            '../views/previews/PreviewDashboard'
import PreviewEditing from              '../views/previews/PreviewEditing'
import PreviewEmail from                '../views/previews/PreviewEmail'
import PreviewAddOns from               '../views/previews/PreviewAddOns'
import PreviewLegal from                '../views/previews/PreviewLegal'
import PreviewLists from                '../views/previews/PreviewLists'
import PreviewLocalization from         '../views/previews/PreviewLocalization'
import PreviewMap from                  '../views/previews/PreviewMap'
import PreviewNavOther from             '../views/previews/PreviewNavOther'
import PreviewNavType from              '../views/previews/PreviewNavType'
import PreviewNotifications from        '../views/previews/PreviewNotifications'
import PreviewOnboarding from           '../views/previews/PreviewOnboarding'
import PreviewPricing from              '../views/previews/PreviewPricing'
import PreviewProfile from              '../views/previews/PreviewProfile'
// import PreviewWelcome from              '../views/previews/PreviewWelcome'

const Pages = [
  {
    id: `wizard_welcome`,
    title: `Wizard Welcome`,
    inWizard: true,
  },
  {
    id: `wizard_platform`,
    title: `Wizard Platform`,
    inWizard: true,
  },
  {
    id: `wizard_type`,
    title: `Wizard Type`,
    inWizard: true,
  },
  {
    id: `wizard_colors`,
    title: `Wizard Colors`,
    inWizard: true,
  },
  {
    id: `user_basics`,
    title: `Basics`,
    pageGroup: `users`,
    PreviewComponent: PreviewDashboard,
  },
  {
    id: `user_data`,
    title: `Data`,
    pageGroup: `users`,
    PreviewComponent: PreviewProfile,
  },
  {
    id: `user_features`,
    title: `Features`,
    pageGroup: `users`,
    PreviewComponent: PreviewProfile,
  },
  {
    id: `data_name`,
    title: `Basics`,
    pageGroup: `data`,
    PreviewComponent: PreviewLists,
    dataPage: true,
  },
  {
    id: `data_attributes`,
    title: `Data`,
    pageGroup: `data`,
    PreviewComponent: PreviewAddOns,
    dataPage: true,
  },
  {
    id: `data_add_ons`,
    title: `Features`,
    pageGroup: `data`,
    PreviewComponent: PreviewAddOns,
    dataPage: true,
  },
  {
    id: `actions`,
    title: `Actions`,
    pageGroup: `data`,
    PreviewComponent: PreviewActions,
    dataPage: true,
  },
  {
    id: `lists`,
    title: `Lists`,
    pageGroup: `data`,
    PreviewComponent: PreviewLists,
    dataPage: true,
  },
  {
    id: `editing`,
    title: `Editing`,
    pageGroup: `data`,
    PreviewComponent: PreviewEditing,
    dataPage: true,
  },
  {
    id: `dashboard`,
    title: `Dashboard`,
    pageGroup: `other_pages`,
    PreviewComponent: PreviewDashboard,
  },
  {
    id: `feeds`,
    title: `Feeds`,
    pageGroup: `other_pages`,
    PreviewComponent: PreviewDashboard,
  },
  {
    id: `calendar`,
    title: `Calendar`,
    pageGroup: `other_pages`,
    PreviewComponent: PreviewCalendar,
  },
  {
    id: `map`,
    title: `Map`,
    pageGroup: `other_pages`,
    PreviewComponent: PreviewMap,
  },
  {
    id: `onboarding`,
    title: `Onboarding`,
    pageGroup: `extras`,
    PreviewComponent: PreviewOnboarding,
  },
  {
    id: `search`,
    title: `Search`,
    pageGroup: `extras`,
    PreviewComponent: PreviewDashboard,
  },
  {
    id: `notifications`,
    title: `Notifications`,
    pageGroup: `extras`,
    PreviewComponent: PreviewNotifications,
  },
  {
    id: `pricing`,
    title: `Pricing`,
    pageGroup: `extras`,
    PreviewComponent: PreviewPricing,
  },
  {
    id: `email`,
    title: `Email`,
    pageGroup: `extras`,
    PreviewComponent: PreviewEmail,
  },
  {
    id: `support_pages`,
    title: `Support`,
    pageGroup: `extras`,
    PreviewComponent: PreviewNavOther,
  },
  {
    id: `security`,
    title: `Security`,
    pageGroup: `extras`,
    PreviewComponent: PreviewAuth,
  },
  {
    id: `localization`,
    title: `Localization`,
    pageGroup: `extras`,
    PreviewComponent: PreviewLocalization,
  },
  {
    id: `legal`,
    title: `Legal`,
    pageGroup: `extras`,
    PreviewComponent: PreviewLegal,
  },
  {
    id: `navigation_type`,
    title: `Layout`,
    pageGroup: `style`,
    PreviewComponent: PreviewNavType,
  },
  {
    id: `colors`,
    title: `Colors`,
    pageGroup: `style`,
    PreviewComponent: PreviewDashboard,
  },
  {
    id: `feel`,
    title: `Feel`,
    pageGroup: `style`,
    PreviewComponent: PreviewDashboard,
  },
]

export default Pages
