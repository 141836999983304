import styled from 'styled-components/macro'

export const QuestionWrapper = styled.div`
  padding: 0 0 2rem;
  opacity: ${p => p.comingSoon && .5};
`
export const QuestionTitle = styled.h4`
`
export const QuestionDescription = styled.div`
  margin: 0 0 .5rem;
  color: var(--text-secondary);
`
export const Example = styled.div`
  margin: 0 0 1rem;
  color: var(--text-secondary);
  font-size: var(--s);

  &:last-child {
    margin-bottom: 0;
  }
`
export const Option = styled.label`
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: .5rem;
  /* border-radius: 4px; */
  padding: .5rem 0;
  /* margin: 0 0 .5rem; */
  user-select: none;
  cursor: ${p => p.disabled ? 'auto' : 'pointer'};
  /* background: ${p => p.disabled && 'var(--background-item)'}; */
  /* border-bottom: ${p => p.disabled ? '1px solid var(--input-border-light)' : '1px solid var(--input-border)'}; */
  border-bottom: 1px solid var(--hairline);
  /* box-shadow: ${p => p.disabled ? 'none' : '0 2px 4px var(--shadow)'}; */
  opacity: ${p => p.disabled && 0.5};
  
  &:hover {
    /* border: ${p => p.disabled ? '1px solid var(--input-border-light)' : '1px solid var(--input-border-dark)'}; */
  }

  input[type="radio"],
  input[type="checkbox"] {
    margin: 6px;
  }
`
export const Consequences = styled.div`
  color: var(--text-secondary);
  font-size: var(--s);
`
export const OptionTitle = styled.div`
  color: var(--text-primary);
  font-size: var(--m);
  font-weight: 500;
`
export const OptionDescription = styled.div`
  color: var(--text-secondary);
  font-size: var(--s);
`
export const QuestionError = styled.div`
  color: var(--destructive);
  font-size: var(--s);
  margin: .5rem 0 0;
`
export const Badge = styled.div`
  display: inline-block;
  padding: 4px 7px;
  margin: 0 0 0 .5rem;

  background: var(--background-item);
  border-radius: 4px;

  color: var(--text-secondary);
  font-size: var(--xs);
  font-weight: 500;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  line-height: 1;

  user-select: none;
`
