// import styled from 'styled-components/macro'

import { Select } from '../../styles/Forms'
import {
  QuestionWrapper,
  QuestionTitle,
  QuestionDescription,
  Example,
  Consequences,
  QuestionError,
  Badge,
} from '../../styles/Questions'

const QuestionSelect = ({
  question,
  formData,
  register,
  errors,
  context,
}) => {

  const { id, title, description, example, choices, comingSoon } = question

  const questionAnswer = formData[id]
  const chosenAnswer = choices && choices.find(c => c.id === questionAnswer)

  return <QuestionWrapper>
    <QuestionTitle>
      { title(context) }
      { comingSoon && <Badge>Coming soon</Badge>}
    </QuestionTitle>
    {description && <QuestionDescription>{ description(context) }</QuestionDescription> }
    {example && <Example>{ example }</Example> }
    <Select {...register(id)}>
      { choices && choices.map(choice => {
        return <option
          key={choice.id}
          value={choice.id}
          disabled={choice.disabled || comingSoon}
        >
          {choice.title}
          {choice.id === question.default(context) && ' (Default)'}
          {choice.description && ` - ${choice.description}`}
        </option>
      })}
    </Select>
    {chosenAnswer && chosenAnswer.consequence && <Consequences>{ chosenAnswer.consequence }</Consequences> }
    {errors[id] && <QuestionError>This field is required</QuestionError>}
  </QuestionWrapper>
}

export default QuestionSelect
