import styled from 'styled-components/macro'

import NavSecondary from './components/NavSecondary'
import NavMain from './components/NavMain'
import {
  AppMain,
  PageHeader,
  PageHeaderGlobal,
  PopupAvatar,
  PageTitle,
  SearchInput,
  ContentBox,
  AppBody,
  AppNav,
} from '../../styles/Previews'

const PreviewCalendar = ({ context }) => {
  const {
    search_yn,
    navigation_type_picker,
    calendar_yn,
  } = context

  return <AppBody>

    <AppNav>
      <NavMain context={context} selectedPage="calendar" />
      <NavSecondary context={context} />
    </AppNav>

    <AppMain>
      {calendar_yn === 'calendar_y' ? 
        <CalendarWrapper>
          <PageHeader>
            <div>
              <PageTitle>Calendar</PageTitle>
            </div>
            <PageHeaderGlobal>
              {search_yn === 'search_y' && navigation_type_picker !== 'horizontal' && <SearchInput type="search" placeholder="Search..." />}
              {navigation_type_picker !== 'horizontal' && 
                <PopupAvatar alt="profile pic" src="https://www.placebear.com/100/100" size={24} />
              }
            </PageHeaderGlobal>
          </PageHeader>
    
          <Calendar />
        </CalendarWrapper>
      :
        <>
          <ContentBox />
          <ContentBox />
          <ContentBox />
        </>
      }
    </AppMain>
  </AppBody>
}

const CalendarWrapper = styled.div`
  height: 100%;
`
const Calendar = styled.div`
  width: 100%;
  height: 400px;
  background: #eee;
`

export default PreviewCalendar
