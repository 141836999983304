import { useRef } from 'react'
import styled from 'styled-components/macro'
import useSize from '@react-hook/size'

import dots from '../../svg/previews/dots.svg'
import Preview from '../previews/Preview'

const SHOW_WATER_MARK = false

const PanelPreview = props => {

  const padding = 50

  const outerTarget = useRef(null)
  const innerTarget = useRef(null)
  const [ outerWidth, outerHeight ] = useSize(outerTarget)
  const [ innerWidth, innerHeight ] = useSize(innerTarget)

  // TODO - maybe this isn't the best place to add the padding?
  const widthRatio  = (innerWidth  + padding) / outerWidth
  const heightRatio = (innerHeight + padding) / outerHeight
  const biggerRadio = Math.max(widthRatio, heightRatio)
  const scaleFactor = Math.min(1 / biggerRadio, 1)

  // console.log(`${outerWidth} x ${outerHeight} --vs-- ${innerWidth} x ${innerHeight}  \nwidthRatio: ${widthRatio} | heightRatio: ${heightRatio}   \nbigger: ${biggerRadio}  \nscaleFactor ${scaleFactor}`)

  const blockerMessage = `Hi, I know this div isn't really preventing you from removing the watermark, but please buy this design anyway, I worked really hard on it. Thank you :'-)`

  return <PreviewWrapper ref={outerTarget}>
    <PreviewBadge>Preview</PreviewBadge>
    <Blocker data-message={blockerMessage}></Blocker>
    {SHOW_WATER_MARK && <Watermark>reflow.design</Watermark>}
    <PreviewInner ref={innerTarget} scaleFactor={scaleFactor} perspective={false}>
      <Preview {...props} />
    </PreviewInner>
  </PreviewWrapper>
}

const PreviewWrapper = styled.div`
  position: relative;
  overflow: hidden;  
  user-select: none !important;
  cursor: auto !important;
  background-color: var(--background-item);
  background-image: url(${dots});
  background-position: 20px 20px; /* offset so dots aren't in the corner */

  // used to be sized by grid parent, but now with onboarding TourPoints,
  // we need to make sure it fills whatever parent it has
  width: 100%;
  height: 100%;
`
const Blocker = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
`
const Watermark = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  font-weight: bold;
  font-size: 10vh;
  opacity: .05;
  z-index: 20;
`
const PreviewInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale( ${p => p.scaleFactor || 1} ) ${p => p.perspective && 'perspective(1500px) rotateX(19deg) rotateY(-12deg) rotateZ(6deg)'};
`
const PreviewBadge = styled.div`
  background: var(--background-item);
  padding: .25rem .5rem;

  position: absolute;
  top: .5rem;
  left: .5rem;
  
  color: var(--text-tertiary);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-size: var(--xs);

  border-radius: 4px;
  z-index: 10;
`

export default PanelPreview
