import styled from 'styled-components/macro'

import {
  FloatingPage,
  PageHeader,
  PageHeaderGlobal,
  PopupAvatar,
  Breadcrumbs,
  PageTitle,
  SearchInput,
} from '../../styles/Previews'

const PreviewLocalization = ({ context }) => {
  const {
    navigation_type_picker,
    search_yn,
    language_yn,
    time_zone_yn,
    currency_yn,
    week_start_yn,
  } = context

  if (language_yn !== 'language_y' && time_zone_yn !== 'time_zone_y' && currency_yn !== 'currency_y' && week_start_yn !== 'week_start_y') {
    return '(no localization settings)'
  }

  return <FloatingPage>

    <PageHeader>
      <div>
        <Breadcrumbs>Settings › Localization</Breadcrumbs>
        <PageTitle>Localization</PageTitle>
      </div>
      <PageHeaderGlobal>
        {search_yn === 'search_y' && navigation_type_picker !== 'horizontal' && <SearchInput type="search" placeholder="Search..." />}
        {navigation_type_picker !== 'horizontal' && 
          <PopupAvatar alt="profile pic" src="https://www.placebear.com/100/100" size={24} />
        }
      </PageHeaderGlobal>
    </PageHeader>

    {language_yn === 'language_y' && <Section>
      <SectionHeading>Default language</SectionHeading>
      <select>
        <option>English (default)</option>
      </select>
    </Section>}
    
    {time_zone_yn === 'time_zone_y' && <Section>
      <SectionHeading>Timezone</SectionHeading>
      <select>
        <option>New York, NY - Eastern Standard Time (default)</option>
      </select>
    </Section>}

    {currency_yn === 'currency_y' && <Section>
      <SectionHeading>Currency</SectionHeading>
      <select>
        <option>$ - US Dollar (default)</option>
      </select>
    </Section>}
    
    {week_start_yn === 'week_start_y' && <Section>
      <SectionHeading>Week start</SectionHeading>
      <select>
        <option>Week starts on Sunday (default)</option>
      </select>
    </Section>}

  </FloatingPage>
}

const Section = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #ddd;
`
const SectionHeading = styled.div`
  font-weight: bold;
  margin: 0 0 .5rem;
`

export default PreviewLocalization
