import pluralize from 'pluralize'

// https://stackoverflow.com/a/1026087
const capitalizeFirstLetter = ([ first, ...rest ], locale = navigator.language) => {
  return first && first.toLocaleUpperCase(locale) + rest.join('')
}
const unCapitalizeFirstLetter = ([ first, ...rest ], locale = navigator.language) => {
  return first && first.toLocaleLowerCase(locale) + rest.join('')
}

function getCollectionName(context, { plural, capital }) {
  const name = context?.currentCollection?.collection_name_plural || 'item'
  const pluralizedName = !plural ? pluralize.singular(name) : name
  return capital ? capitalizeFirstLetter(pluralizedName) : unCapitalizeFirstLetter(pluralizedName)
}

export default getCollectionName
