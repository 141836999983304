import styled from 'styled-components/macro'

const PreviewAttrFile = ({ $large }) => {
  if (!$large) {
    return 'my_document.pdf'
  }
  return <FileWrapper>
    <FileImage />
    <div>
      my_document.pdf
    </div>
  </FileWrapper>
}

const FileWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 1rem;
`
const FileImage = styled.div`
  width: 40px;
  height: 50px;
  border: 1px solid;
  border-radius: 6px;
`

export default PreviewAttrFile
