import { useState, useContext } from 'react'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'

import { AppContext } from './App'
import { Button } from '../styles/Buttons'
import Form from './Form'
// import PreviewVideo from './PreviewVideo'
import Pages from '../data/pages'

const Wizard = () => {

  const navigate = useNavigate()

  const {
    formData,
    saveFormData,
  } = useContext(AppContext)

  // manage pages
  const pages = Pages.filter(p => p.inWizard)
  const [ currentPageIndex, setCurrentPageIndex ] = useState(0)
  const currentPage = pages[currentPageIndex]
  const currentPageId = currentPage.id
  const isOnLastPage = currentPageIndex === pages.length - 1
  const isOnFirstPage = currentPageIndex === 0

  const goNext = () => {
    if (isOnLastPage) {
      navigate('/build')
    } else {
      setCurrentPageIndex(currentPageIndex + 1)
    }
  }
  const goPrev = event => {
    event.preventDefault()
    if (isOnFirstPage) {
      throw new Error(`Can't advance past the first page`)
    } else {
      setCurrentPageIndex(currentPageIndex - 1)
    }
  }

  const FormNav = ({ formState }) => <WizardNavWrapper>
    <div>
      {!isOnFirstPage &&
        <Button tertiary onClick={goPrev}>Back</Button>
      }
    </div>
    <div>
      {Object.keys(formState.errors).map( errorId => {
        return <ErrorText key={errorId}>Please use a valid {errorId}</ErrorText>
      })}
      <Button type="submit" disabled={!formState.isValid}>{ isOnLastPage ? 'Generate my custom design' : 'Next'}</Button>
    </div>
  </WizardNavWrapper>

  return <WizardLayoutWrapper>
    <WizardContent>

      <WizardFormWrapper>
        <p>
          Step {currentPageIndex + 1} of {pages.length}
        </p>
        <Form
          key={currentPageId}
          currentPageId={currentPageId}
          saveFormData={saveFormData}
          formData={formData}
          onFormSubmit={goNext}
          FormNav={FormNav}
        />
      </WizardFormWrapper>

      {/* <WizardMediaWrapper>
        <PreviewVideo />
      </WizardMediaWrapper> */}

    </WizardContent>
  </WizardLayoutWrapper>
}

const WizardLayoutWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;

  width: 100vw;
  height: 100vh;

  background-size: cover;
  background: var(--background-item);
  /* background: url('/gradient.png'); */
`
const WizardContent = styled.div`
  display: grid;
  /* grid-template-columns: 3fr 2fr; */
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;

  background: white;
  border-radius: 10px;
  padding: 2rem;
  width: 600px; /* wider when we have more content */
  min-height: 600px;
  max-width: 100vw;
  max-height: 100vh;
  
  @media(max-width: 800px) {
    grid-template-columns: auto;
    /* grid-template-rows: auto 200px; height of preview wrapper */
    grid-template-rows: 1fr;
    height: 100vh;
    border-radius: 0;
  }
  `

const WizardFormWrapper = styled.div`
  position: relative;
  max-height: 100%;
`
// const WizardMediaWrapper = styled.div`
//   @media(max-width: 800px) {
//     order: -1;
//   }
// `

const WizardNavWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  justify-content: space-between;
`

const ErrorText = styled.div`
  color: red;
  margin: 0 0 1rem;
`

export default Wizard
