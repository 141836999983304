import styled from 'styled-components/macro'

import NavSecondary from './components/NavSecondary'
import NavMain from './components/NavMain'
import {
  AppMain,
  PageHeaderGlobal,
  PopupAvatar,
  SearchInput,
  ContentBox,
  AppBody,
  AppNav,
} from '../../styles/Previews'

const PreviewMap = ({ context }) => {
  const {
    search_yn,
    navigation_type_picker,
    map_yn,
  } = context

  return <AppBody>

    <AppNav>
      <NavMain context={context} selectedPage="map" />
      <NavSecondary context={context} />
    </AppNav>

    <AppMain>
      {map_yn === 'map_y' ? 
        <MapWrapper>

          <MapUpperRight>
            <PageHeaderGlobal>
              {search_yn === 'search_y' && navigation_type_picker !== 'horizontal' && <SearchInput type="search" placeholder="Search..." />}
              {navigation_type_picker !== 'horizontal' && 
                <PopupAvatar alt="profile pic" src="https://www.placebear.com/100/100" size={24} />
              }
            </PageHeaderGlobal>
          </MapUpperRight>
    
          <Map />
        </MapWrapper>
      :
        <>
          <ContentBox />
          <ContentBox />
          <ContentBox />
        </>
      }
    </AppMain>
  </AppBody>
}

const MapWrapper = styled.div`
  height: calc(100% + 4rem); /* very brittle */
  display: grid;
  margin: -2rem;
`
const MapUpperRight = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 10;
`
const Map = styled.div`
  width: 100%;
  background: var(--oc-gray-3);
`

export default PreviewMap
