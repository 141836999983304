import styled from 'styled-components/macro'

const PreviewAttrImages = ({ $large }) => {
  if (!$large) {
    return '3 images'
  }
  return <ImageWrapper>
    <Image />
    <Image />
    <Image />
  </ImageWrapper>
}

const ImageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
`
const Image = styled.div`
  min-height: 80px;
  background: #eee;
`

export default PreviewAttrImages
