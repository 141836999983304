import * as Popover from '@radix-ui/react-popover'
import styled from 'styled-components/macro'
import { Button } from '../styles/Buttons'
import { TourSteps } from '../data/tourSteps'

const TourPoint = ({
  children,
  stepId,
  nextTourStep,
  skipTour,
  currentOnboardingStepIndex,
}) => {
  
  const thisStepIndex = TourSteps.findIndex(ts => ts.id === stepId)
  const isOpen = currentOnboardingStepIndex === thisStepIndex

  const currentStep = TourSteps[currentOnboardingStepIndex]
  const { popoverSide, title, content, cta, spotlight } = currentStep || {}
  
  return <TourPointPopoverRoot open={isOpen} onOpenChange={nextTourStep}>

    <TourPointPopoverAnchor $highlight={isOpen} $spotlight={spotlight}>
      {children}
    </TourPointPopoverAnchor>

    {isOpen &&
      <>
        <TourPointPopoverContent side={popoverSide} sideOffset={8}>
          <TourPointArrow offset={12} />

          <TourPointSkip onClick={skipTour}>
            <span className="iconify" data-icon={`mdi-close`} />
          </TourPointSkip>

          <TourPointSteps>{currentOnboardingStepIndex + 1} of {TourSteps.length}</TourPointSteps>

          <TourPointTitle>{title}</TourPointTitle>

          {content}

          {/* <TourPointImage src={imageSrc} /> */}

          <TourPointClose
            autoFocus
            $wide
            onClick={nextTourStep}
          >
            {cta || 'Got it'}
          </TourPointClose>

        </TourPointPopoverContent>
        <TourPointDialogOverlay />
      </>
    }

  </TourPointPopoverRoot>
}

const TourPointDialogOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: hsl(var(--oc-gray-8) / 60%);
  background: hsl(0 0% 0% / 60%);
  /* background: hsl(0 0% 100% / 80%); */
  top: 0;
  left: 0;
  z-index: 1000;
`
const TourPointPopoverRoot = styled(Popover.Root)`
`
const TourPointPopoverAnchor = styled(Popover.Anchor)`
  position: ${p => p.$highlight && 'relative'};
  z-index: ${p => p.$highlight && '99999999'};

  ${p => p.$highlight && p.$spotlight && `
    &:after {
      content: '';
      background: #ffffffb0;
      width: 200px;
      height: 200px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      border-radius: 10000px;
    }
  `}
`
const TourPointPopoverContent = styled(Popover.Content)`
  min-width: 300px;
  max-width: 400px;
  max-height: 80vh;

  padding: 2rem;
  background: white;
  border-radius: 4px;

  overflow-y: auto;
  z-index: 1500;

  box-shadow: 5px 20px 40px -20px hsl(0 0% 0% / 0.5);
`
const TourPointArrow = styled(Popover.Arrow)`
  fill: white;
`
const TourPointSteps = styled.div`
  margin: 0 0 .5rem;
  font-size: var(--s);
  color: var(--text-secondary);
`
const TourPointTitle = styled.h3`
  margin: 0 0 .5rem;
`
// const TourPointImage = styled.img`
//   display: block;
//   width: 100%;
//   min-height: 100px;
//   background: #eee;
//   margin: 1rem 0 0;
// `

const TourPointClose = styled(Button)`
  /* big CTA */
`
const TourPointSkip = styled(Popover.Close)`
  appearance: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: transparent;
  margin: 0;
  line-height: 1;

  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 4px;
  text-align: inherit;
  
  cursor: pointer;
  opacity: .4;
  
  svg {
    color: var(--text-secondary);
    width: 14px;
    height: 14px;
  }

  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
`

export default TourPoint
