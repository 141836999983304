export const NavItems = [
  {
    id: `dashboard`,
    label: `Dashboard`,
    icon: `gauge`,
    level: `primary_nav`,
    emphasized: true,
    displayTest: context => context.dashboard_yn === 'dashboard_y'
  },
  {
    id: `favorites`,
    label: `Favorites`,
    icon: `heart`,
    level: `primary_nav`,
    emphasized: true,
    displayTest: context => context.collections.some(c => c.favorite_yn === 'favorite_y')
  },
  {
    id: `following`,
    label: `Following`,
    icon: `eye`,
    level: `primary_nav`,
    emphasized: true,
    displayTest: context => context.follow_users_yn === 'follow_users_y'
  },
  {
    id: `suggested`,
    label: `For you`,
    icon: `magic`,
    level: `primary_nav`,
    emphasized: true,
    displayTest: context => context.feeds_items?.includes('suggested')
  },
  {
    id: `popular`,
    label: `Popular`,
    icon: `hot`,
    level: `primary_nav`,
    emphasized: true,
    displayTest: context => context.feeds_items?.includes('popular')
  },
  {
    id: `recent_changes`,
    label: `Recent`,
    icon: `pulse`,
    level: `primary_nav`,
    emphasized: true,
    displayTest: context => context.feeds_items?.includes('recent_changes')
  },
  {
    id: `calendar`,
    label: `Calendar`,
    icon: `calendar`,
    level: `primary_nav`,
    emphasized: true,
    displayTest: context => context.calendar_yn === 'calendar_y'
  },
  {
    id: `map`,
    label: `Map`,
    icon: `map`,
    level: `primary_nav`,
    emphasized: true,
    displayTest: context => context.map_yn === 'map_y'
  },
  {
    id: `help`,
    label: `Help`,
    icon: `lifebuoy`,
    level: `secondary_nav`,
    emphasized: false,
    displayTest: context => context.support_pages_links?.includes('help')
  },
  {
    id: `upgrade`,
    label: `Upgrade`,
    icon: `star`,
    level: `secondary_nav`,
    emphasized: false,
    displayTest: context => context.charge_yn === 'charge_y'
  },
  {
    id: `whats_new`,
    label: `What's new`,
    icon: `shimmer`,
    level: `secondary_nav`,
    emphasized: false,
    displayTest: context => context.support_pages_links?.includes('whats_new')
  },
  {
    id: `inbox`,
    label: `Messages`,
    icon: `inbox`,
    level: `secondary_nav`,
    emphasized: true,
    displayTest: context => context.messaging_yn === 'messaging_y'
  },
  {
    id: `feedback`,
    label: `Feedback`,
    icon: `feedback`,
    level: `secondary_nav`,
    emphasized: false,
    displayTest: context => context.support_pages_links?.includes('feedback')
  },
  {
    id: `notifications`,
    label: `Notifications`,
    icon: `bell`,
    level: `secondary_nav`,
    emphasized: true,
    displayTest: context => context.notifications_in_app_yn === 'notifications_in_app_y'
  },
  {
    id: `invite`,
    label: `Invite`,
    icon: `invite`,
    level: `secondary_nav`,
    emphasized: false,
    displayTest: context => context.teams_yn === 'teams_y'
  },
  {
    id: `cart`,
    label: `Cart`,
    icon: `cart`,
    level: `secondary_nav`,
    emphasized: true,
    displayTest: context => context.collections.some(c => c.item_for_sale_yn === 'item_for_sale_y')
  },
  {
    id: `checklist`,
    label: `Getting started`,
    icon: `progress-check`,
    level: `secondary_nav`,
    emphasized: true,
    displayTest: context => context.onboarding_checklist_yn === 'onboarding_checklist_y'
  },
  {
    id: `settings`,
    label: `Settings`,
    icon: `cog`,
    level: `secondary_nav`,
    emphasized: true,
    displayTest: () => true
  },
]
