export const DripEmails = [
  {
    id: `welcome_email`,
    subject: context => `Welcome to ${context.app_name || 'MyApp'}`,
    body: () => `Hi, I'm Jay, reach out anytime if you need help`,
  },
  {
    id: `how_to`,
    subject: context => `Getting started with ${context.app_name || 'MyApp'}`,
    body: () => `Get the most out of your account by setting up your integrations`,
  },
  {
    id: `demo_invite`,
    subject: () => `Join us for a free demo`,
    body: () => `Join us next week for a 30m tour with our head of sales!`,
  },
  {
    id: `case_studies`,
    subject: () => `Learn how our customers are transforming...`,
    body: () => `Learn how Google, Netflix, and Shopify all increased employee retention`,
  },
  {
    id: `trial_expiring`,
    subject: () => `Your trial will expire in 3 days`,
    body: context => `Upgrade to keep the best features of  ${context.app_name || 'MyApp'}`,
  },
  {
    id: `customer_welcome`,
    subject: () => `Thanks for being a customer!`,
    body: () => `Welcome! Here's your complete setup kit`,
  },
  {
    id: `usage_review`,
    subject: context => `Your weekly ${context.app_name || 'MyApp'} report`,
    body: () => `Activity was up X% this week...`,
  },
  {
    id: `survey`,
    subject: context => `Do you have 5 minutes to help us improve ${context.app_name || 'MyApp'}?`,
    body: () => `Answer a few questions for a chance at a $100 Amazon gift card...`,
  },
]
