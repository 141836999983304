import styled from 'styled-components/macro'
import {
  ContentBox,
} from '../../styles/Previews'

const PreviewLegal = ({ context }) => {
  const {
    legal_name,
    legal_links,
  } = context

  const linkText = {
    terms: `Terms`,
    privacy: `Privacy`,
    dmca: `DMCA`,
    gdpr: `GDPR`,
    accessibility: `Accessibility`,
  }

  return <PreviewLegalWrapper>
    <ContentBox />
    <ContentBox />
    <ContentBox />
    <ContentBox />

    <LegalLine>
      &copy;{new Date().getFullYear()} {legal_name} · {legal_links && legal_links.map(ll => linkText[ll] || 'Link goes here').join(' · ')}
    </LegalLine>
  </PreviewLegalWrapper>
}

const PreviewLegalWrapper = styled.div`
  background: white;
  box-shadow: var(--shadow-elevation-medium);
  border-radius: 4px;
  padding: 2rem;
  min-width: 700px;
`
const LegalLine = styled.div`
  color: var(--text-secondary);
  margin: 1.5rem 0 0;
  text-align: center;
  font-size: var(--s);
  white-space: nowrap;
`

export default PreviewLegal
