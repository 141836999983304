import styled from 'styled-components/macro'
import produce from 'immer'
import { useNavigate } from 'react-router-dom'

import {
  QuestionWrapper,
} from '../../styles/Questions'


const QuestionDeleteCollection = ({
  formData,
  saveFormData,
  currentCollectionId,
}) => {

  const navigate = useNavigate()

  const onDelete = event => {
    event.preventDefault()

    if (!window.confirm('Are you sure you want to delete this collection? This cannot be undone.')) {
      return
    }

    saveFormData(produce(formData, formDataDraft => {
      // TODO - try/catch just in case something weird happens?
      const indexToDelete = formDataDraft.collections.findIndex(c => c.id === currentCollectionId)
      formDataDraft.collections.splice(indexToDelete, 1)
      // TODO - doesn't open accordion?
      navigate(`/build/data_name/${formDataDraft.collections[0].id}`)
    }))
  }

  return <QuestionWrapper>
    <DeleteLink $destructive onClick={onDelete}>Delete collection</DeleteLink>
  </QuestionWrapper>
}

const DeleteLink = styled.div`
  display: block;
  cursor: pointer;
  padding: .25rem;
  text-decoration: none;
  color: var(--text-primary);
  font-size: var(--s);
  opacity: .7;
  
  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
`

export default QuestionDeleteCollection
