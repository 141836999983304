import styled from 'styled-components/macro'

import getCollectionName from '../../helpers/getCollectionName'
import {
  ModalContent,
  ModalTitle,
  Button,
  PageFlowWrapper,
  PageFlowArrow,
} from '../../styles/Previews'
import PreviewLists from './PreviewLists'
import ArrowDown from '../../svg/previews/arrow_down.svg'
import AttributeTypes from '../../data/attribute_types'

const MAX_EDIT_FORM_QUESTIONS = 5

const PreviewEditing = ({ context }) => {
  const {
    editing_yn,
    edit_autosave_yn,
    item_attributes,
  } = context.currentCollection

  const mostImportant = item_attributes?.find(group => group.id === 'most_important')
  const attributes = mostImportant?.items?.slice(0, MAX_EDIT_FORM_QUESTIONS - 1)

  return <PageFlowWrapper>

    <PreviewLists context={context} />

    {editing_yn === 'editing_y' &&
      <>
        <PageFlowArrow src={ArrowDown} />

        <ModalContent>

          <ModalTitle>Edit {getCollectionName(context, {})}</ModalTitle>

          <PreviewEditForm>
              <PreviewEditInputWrapper>
                <PreviewEditLabel>
                  Name
                </PreviewEditLabel>
                <PreviewEditInput>
                  My item
                </PreviewEditInput>
              </PreviewEditInputWrapper>
              {attributes?.map( attribute => {
                const { PreviewComponent } = AttributeTypes.find(at => at.id === attribute.type) || AttributeTypes.find(at => at.id === 'attr_type_unknown')
                return <PreviewEditInputWrapper key={attribute.id}>
                  <PreviewEditLabel>
                    {attribute.title}
                  </PreviewEditLabel>
                  <PreviewEditInput>
                    <PreviewComponent />
                  </PreviewEditInput>
                </PreviewEditInputWrapper>
              })}
          </PreviewEditForm>

          { edit_autosave_yn === 'edit_autosave_y' ?
            <ButtonRow>
              <p>Saved just now</p>
              <Button>Done</Button>
            </ButtonRow>
            :
            <ButtonRow>
              <Button $secondary>Cancel</Button>
              <Button>Save</Button>
            </ButtonRow>
          }
        </ModalContent>
      </>
    }

  </PageFlowWrapper>
}

const ButtonRow = styled.div`
  display: grid;
  grid-gap: .5rem;
  grid-template-columns: max-content max-content;
  justify-content: end;
  margin: 1.5rem 0 -.5rem;
`
const PreviewEditForm = styled.div`
  width: 100%;
`
const PreviewEditInputWrapper = styled.div`
  padding: 0.5rem 0;
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-column-gap: 2rem;
`
const PreviewEditLabel = styled.div`
  font-weight: 500;
  font-size: var(--s);
`
const PreviewEditInput = styled.div`
  border: 1px solid var(--hairline);
  padding: .25rem;
`

export default PreviewEditing
