import styled from 'styled-components/macro'

import {
  ModalContent,
  ModalTitle,
  Button,
  ContentBox,
} from '../../styles/Previews'

const PreviewPricing = ({ context }) => {
  const {
    charge_yn,
    free_tier_yn,
    pricing_free_trial_yn,
  } = context

  const trial = pricing_free_trial_yn === 'pricing_free_trial_y'

  if (charge_yn === 'charge_n') {
    return `(no pricing)`
  }

  return <ModalContent>

    <ModalTitle>Choose plan</ModalTitle>

    <TierWrapper>
      { free_tier_yn === 'free_tier_y' ?
        <Tier>
          <TierPrice>
            Free
          </TierPrice>
          <ContentBox />
          <Button $secondary>Current</Button>
        </Tier>
        :
        <Tier>
          <TierPrice>
            $▮▮▮▮
          </TierPrice>
          <ContentBox />
          <Button $secondary>Current</Button>
        </Tier>
      }
      <Tier>
        <TierPrice>
          $▮▮▮▮
        </TierPrice>
        <ContentBox />
        <Button>{trial ? 'Start trial' : 'Upgrade'}</Button>
      </Tier>
      <Tier>
        <TierPrice>
          $▮▮▮▮
        </TierPrice>
        <ContentBox />
        <Button>{trial ? 'Start trial' : 'Upgrade'}</Button>
      </Tier>
    </TierWrapper>
  </ModalContent>
}

const TierWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
`
const Tier = styled.div`
  border: 1px solid var(--hairline);
  border-radius: 4px;
  padding: 1rem;
  box-shadow: 0 2px 4px var(--shadow);

  &:last-child {
    margin-bottom: 0;
  }
`
const TierPrice = styled.div`
  margin: 0 0 1rem;
  font-size: var(--l);
  font-weight: 500;
`

export default PreviewPricing
