import * as Switch from '@radix-ui/react-switch'
import styled from 'styled-components/macro'

const DIAMETER = 24
const INSET = 2
const WIDTH = DIAMETER * 1.8

export const SwitchRoot = styled(Switch.Root)`
  position: relative;
  width: ${DIAMETER * 1.8}px;
  height: ${DIAMETER}px;
  
  border-radius: 1000px;
  background: var(--background-item-dark);
  border: none;
  box-shadow: inset 0 2px 4px var(--shadow);
  cursor: pointer;

  &[data-state="checked"] {
    background: var(--brand-primary);
  }

  opacity: ${p => p.disabled && .5};
`
export const SwitchThumb = styled(Switch.Thumb)`
  position: absolute;
  top: ${INSET}px;
  left: ${INSET}px;
  
  display: block;
  width: ${DIAMETER - INSET*2}px;
  height: ${DIAMETER - INSET*2}px;
  
  border-radius: 1000px;
  background: white;
  box-shadow: var(--shadow-elevation-medium);
  transition: all .2s ease;

  &[data-state="checked"] {
    left: ${ WIDTH + INSET - DIAMETER}px;
  }
`
