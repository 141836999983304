import { useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
// import styled from 'styled-components/macro'

import {
  DialogRoot,
  DialogTrigger,
} from '../../../components/Dialog'
import ListBuilderModal from './ListBuilderModal'
import { 
  SortableListItem,
  SortableDragHandle,
  SortableMenuIcon,
} from '../../../styles/Sortables'

const ListBuilderItem = ({
  item,
  index,
  EditorForm,
  removeItem,
  itemType,
  formData,
  saveFormData,
  currentCollectionId,
  isDataPage,
  question,
  answerGroupId,
}) => {
  const [ isOpen, setIsOpen ] = useState(false)
  
  return <Draggable draggableId={item.id} index={index}>
    {( { innerRef, draggableProps, dragHandleProps }, snapshot) => {
      return <DialogRoot
        id={item.id}
        open={isOpen}
        onOpenChange={() => setIsOpen(!isOpen)}
        modal={true}
      >
        {/*
          TODO
          instead of using the whole thing as a trigger,
          just set isOpen on click?
          Triggers are not strictly necessary apparently.
          Plus, we can move modal logic up to the questionBuilder component
          and save data passing through here.
        */}
        <DialogTrigger asChild={true}>
          <SortableListItem
            ref={innerRef}
            {...draggableProps}
            isDragging={snapshot.isDragging}
            style={draggableProps.style}
          >
            <SortableDragHandle {...dragHandleProps}>
              <svg style={{width:'24px',height:'24px'}} viewBox="0 0 24 24">
                <path fill="currentColor" d="M7,19V17H9V19H7M11,19V17H13V19H11M15,19V17H17V19H15M7,15V13H9V15H7M11,15V13H13V15H11M15,15V13H17V15H15M7,11V9H9V11H7M11,11V9H13V11H11M15,11V9H17V11H15M7,7V5H9V7H7M11,7V5H13V7H11M15,7V5H17V7H15Z" />
              </svg>
            </SortableDragHandle>

            <span>
              {item.title}
            </span>

            <SortableMenuIcon onClick={removeItem}>
              x
              {/* <svg style={{width:'24px',height:'24px'}} viewBox="0 0 24 24">
                <path fill="currentColor" d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z" />
              </svg> */}
            </SortableMenuIcon>

          </SortableListItem>
        </DialogTrigger>

        <ListBuilderModal
          item={item}
          itemType={itemType}
          formData={formData}
          saveFormData={saveFormData}
          close={() => setIsOpen(false)}
          EditorForm={EditorForm}
          currentCollectionId={currentCollectionId}
          isDataPage={isDataPage}
          question={question}
          answerGroupId={answerGroupId}
        />
      </DialogRoot>
    }}
  </Draggable>
}

export default ListBuilderItem
