import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import styled from 'styled-components/macro'
import produce from 'immer'

import Questions from '../data/questions'
import QuestionUnknown from './questions/QuestionUnknown'

const FADE_BAR_HEIGHT = '3rem'

const Form = ({
  currentPageId,
  saveFormData,
  formData,
  manualErrors,
  onFormSubmit,
  FormNav,
  currentCollectionId,
  isDataPage,
}) => {

  const questions = Questions.filter(q => q.page === currentPageId)

  // things the question needs to render
  // TODO - use the same context that Previews use? move up the tree to app state?
  // Make sure everyone that calls <Form> passes context if you do this
  const context = {
    ...formData,
    currentCollectionId,
    currentCollection: formData.collections.find(c => c.id === currentCollectionId),
    isDataPage,
  }

  // generate schema for this form
  const yupValidations = questions.reduce( (result, question) => {
    if (!question.yup_validation) {
      return result
    }
    return {
      ...result,
      [question.id]: question.yup_validation,
    }
  }, {})
  const schema = yup.object(yupValidations)
  
  // generate default values for this form
  // TODO - don't duplicate here and in Wizard.js
  // TODO - move this to the question renderer - can it happen every render anyway?
  // I guess not if we need default values on form, but maybe we can move that to per-question
  const defaultValues = questions.reduce( (result, question) => {

    let questionAnswer
    
    if (question.default) {
      questionAnswer = question.default(context)
    }
    
    if (!isDataPage) {
      if (formData[question.id]) {
        questionAnswer = formData[question.id]
      }
    } else {
      const collectionIndex = formData.collections.findIndex(c => c.id === currentCollectionId)
      if (collectionIndex >= 0) {
        if (formData.collections[collectionIndex][question.id]) {
          questionAnswer = formData.collections[collectionIndex][question.id]
        }
      }
    }

    if (!questionAnswer) {
      return result
    }

    return {
      ...result,
      [question.id]: questionAnswer,
    }
  }, {})

  // react-hook-form
  const {
    register,
    watch,
    handleSubmit,
    formState,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    
    // maybe if react-hook-form worked differently we wouldn't also need to set these here,
    // but when we don't set it the state is updated and the form is empty,
    // so i guess we gotta keep it
    defaultValues,
  })
  
  // when the form first loads
  // save the default values to formData so previews are ready
  const persistCurrentAndDefaultValues = () => {
    if (!isDataPage) {
      saveFormData(defaultValues)
      return
    }
    const newData = produce(formData, formDataDraft => {
      const collectionIndex = formDataDraft.collections.findIndex(c => c.id === currentCollectionId)

      // don't override id
      const currentData = formDataDraft.collections[collectionIndex]
      formDataDraft.collections[collectionIndex] = {
        ...currentData,
        ...defaultValues,
      }

    })
    saveFormData(newData)
  }
  useEffect(persistCurrentAndDefaultValues, [currentPageId, currentCollectionId]) // eslint-disable-line
  
  // update form on changes
  useEffect(() => {
    const updateFormData = value => {
      if (!isDataPage) {
        saveFormData(value)
        return
      }
      const newData = produce(formData, formDataDraft => {
        const collectionIndex = formDataDraft.collections.findIndex(c => c.id === currentCollectionId)
        const currentData = formDataDraft.collections[collectionIndex]
        formDataDraft.collections[collectionIndex] = {
          ...currentData,
          ...value,
        }
      })
      saveFormData(newData)
    }
    const subscription = watch(updateFormData)
    return () => subscription.unsubscribe()
  }, []) // eslint-disable-line

  return <FormWrapper onSubmit={onFormSubmit && handleSubmit(onFormSubmit)}>
    {questions.map(question => {

      if (question.displayTest && !question.displayTest(context)) {
        return false
      }

      const QuestionComponent = question.QuestionComponent || QuestionUnknown

      return <QuestionComponent
        key={question.id}
        question={question}
        formData={formData}
        questions={Questions}
        manualErrors={manualErrors}
        errors={errors}
        register={register}
        saveFormData={saveFormData}
        control={control}
        context={context}
        isDataPage={isDataPage}
        currentCollectionId={currentCollectionId}
      />
    })}

    {FormNav &&
      <FormNavWrapper>
        <FormNav formState={formState} />
      </FormNavWrapper>
    }
  </FormWrapper>
}

const FormWrapper = styled.form`
  // make fade bar not overlap shit
  /* padding: 0rem 0rem ${FADE_BAR_HEIGHT}; */
  
  max-height: 100%;
  overflow-y: auto;

  // for onboarding z-index stuff
  background: white;
  padding: 2rem;
  margin: -2rem;
`
const FormNavWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0rem;
  right: 0rem;

  /* width: 100%; */
  padding: 1vh 0;
  border-top: 1px solid #eee;
  background: white;

  &:after {
    content: '';
    width: 100%;
    height: ${FADE_BAR_HEIGHT};
    background: linear-gradient(0deg, hsl(0 0% 100% / 1), hsl(0 0% 100% / 0));
    position: absolute;
    top: calc(-${FADE_BAR_HEIGHT} - 1px);
    left: 0;
    pointer-events: none;
  }
`

export default Form
