import styled from 'styled-components/macro'

const PreviewAttrLocation = ({ $large }) => {
  return $large ? <Map /> : 'Ann Arbor, MI'
}

const Map = styled.div`
  width: 100%;
  height: 200px;
  background: #eee;
  border-radius: 6px;
`

export default PreviewAttrLocation
