import styled from 'styled-components/macro'

import getCollectionName from '../../helpers/getCollectionName'
import {
  PageHeader,
  PageHeaderGlobal,
  // Breadcrumbs,
  PageTitle,
  ContentBox,
  PopupAvatar,
  FloatingPage,
  ItemSummary,
} from '../../styles/Previews'
import { Button } from '../../styles/Buttons'
import AttributeTypes from '../../data/attribute_types'

const PreviewAddOns = ({ context }) => {
  const {
    profile_pic_yn,
    gallery_banner_pic,
    favorite_yn,
    share_yn,
    item_for_sale_yn,
    item_ratings_reviews_yn,
    item_comments_yn,
    item_attributes,
  } = context.currentCollection

  const mostImportant = item_attributes && item_attributes.find(group => group.id === 'most_important')
  const lessImportant = item_attributes && item_attributes.find(group => group.id === 'less_important')

  return <FloatingPage>

    <PageHeader>
      <div>
        { gallery_banner_pic === 'gallery_pics' &&
          <PreviewAttrGallery />
        }
        {/* <Breadcrumbs>{getCollectionName(context, { plural: true, capital: true })} › My {getCollectionName(context, {})}</Breadcrumbs> */}
        <PageTitle>My {getCollectionName(context, {})}</PageTitle>
        { profile_pic_yn === 'profile_pic_y' &&
          <PopupAvatar alt="profile pic" src="https://www.placebear.com/200/200" size={24} />
        }
        { favorite_yn === 'favorite_y' &&
          <Button $small $secondary>Add to favorites</Button>
        }
        { share_yn === 'share_y' &&
          <Button $small $secondary>Share...</Button>
        }
        { item_for_sale_yn === 'item_for_sale_y' &&
          <Button $small $secondary>Add to cart</Button>
        }

        <ItemSummary>
          { item_ratings_reviews_yn === 'item_ratings_reviews_y' && '★★★★☆'}
          {mostImportant && mostImportant.items && mostImportant.items.map( (attribute, index, array) => {
            const { PreviewComponent } = AttributeTypes.find(at => at.id === attribute.type) || AttributeTypes.find(at => at.id === 'attr_type_unknown')
            return <span key={attribute.id}>
              <PreviewAttributesHeaderItem>
                <PreviewComponent />
              </PreviewAttributesHeaderItem>
              {index + 1 < array.length && ' · '}
            </span>
          })}
        </ItemSummary>

      </div>
      <PageHeaderGlobal>
      </PageHeaderGlobal>
    </PageHeader>

    <PreviewAttributesColumns>
      <div>
        {mostImportant && mostImportant.items && mostImportant.items.map(attribute => {
          const { PreviewComponent } = AttributeTypes.find(at => at.id === attribute.type) || AttributeTypes.find(at => at.id === 'attr_type_unknown')
          return <PreviewAttributesMainItem key={attribute.id}>
            <PreviewAttributesMainHeader>{attribute.title}</PreviewAttributesMainHeader>
            <PreviewComponent $large />
          </PreviewAttributesMainItem>
        })}

        { item_ratings_reviews_yn === 'item_ratings_reviews_y' &&
          <PreviewAttributesMainItem>
            <PreviewAttributesMainHeader>Ratings</PreviewAttributesMainHeader>
            <p>Average rating: ★★★★☆</p>
            <ContentBox $small />
            <ContentBox $small />
            <ContentBox $small />
          </PreviewAttributesMainItem>
        }
        { item_comments_yn === 'item_comments_y' &&
          <PreviewAttributesMainItem>
            <PreviewAttributesMainHeader>Comments</PreviewAttributesMainHeader>
            <ContentBox $small />
            <ContentBox $small />
            <ContentBox $small />
          </PreviewAttributesMainItem>
        }

      </div>
      <div>
        {lessImportant && lessImportant.items && lessImportant.items.map(attribute => {
          const { PreviewComponent } = AttributeTypes.find(at => at.id === attribute.type) || AttributeTypes.find(at => at.id === 'attr_type_unknown')
          return <PreviewAttributesSidebarItem key={attribute.id}>
            <PreviewAttributesSidebarHeader>{attribute.title}</PreviewAttributesSidebarHeader>
            <PreviewComponent />
          </PreviewAttributesSidebarItem>
        })}
      </div>
    </PreviewAttributesColumns>

  </FloatingPage>
}

const PreviewAttrGallery = styled.div`
  width: 100%;
  height: 80px;
  background: var(--background-item);
  margin: 0 0 1rem;
`
const PreviewAttributesColumns = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 2rem;
`
const PreviewAttributesHeaderItem = styled.div`
  display: inline-block;
`
const PreviewAttributesMainItem = styled.div`
  padding: 0 0 1rem;
  border-bottom: 1px solid #ddd;
  margin: 0 0 1rem;
`
const PreviewAttributesMainHeader = styled.div`
  padding: 0 0 1rem;
  font-size: var(--m);
  font-weight: 500;
`
const PreviewAttributesSidebarItem = styled.div`
  padding: .5rem 0;
`
const PreviewAttributesSidebarHeader = styled.div`
  margin: 0 0 .25rem;
  font-size: var(--s);
`

export default PreviewAddOns
