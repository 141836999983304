import styled from 'styled-components/macro'
import getCollectionName from '../../helpers/getCollectionName'

import NavSecondary from './components/NavSecondary'
import NavMain from './components/NavMain'
import AttributeTypes from '../../data/attribute_types'
import {
  AppMain,
  AppBody,
  AppNav,
  PageHeader,
  PageHeaderGlobal,
  PopupAvatar,
  PageTitle,
  SearchInput,
} from '../../styles/Previews'

const MAX_COLUMNS = 5

const PreviewLists = ({ context }) => {
  const {
    navigation_type_picker,
    search_yn,
  } = context

  const {
    list_size,
    list_grouped_yn,
    item_attributes,
  } = context.currentCollection

  const mostImportant = item_attributes?.find(group => group.id === 'most_important')
  const attributes = mostImportant?.items?.slice(0, MAX_COLUMNS - 1)

  const big = list_size === '5'

  const fakeItems = [0, 1, 2, 3, 4]
  if (!big) {
    fakeItems.push(5, 6, 7)
  }

  return <AppBody>

    <AppNav>
      <NavMain context={context} />
      <NavSecondary context={context} />
    </AppNav>

    <AppMain>

      <PageHeader>
        <div>
          <PageTitle>{getCollectionName(context, { plural: true, capital: true})}</PageTitle>
          {list_grouped_yn === 'list_grouped_y' &&
            <Tabs>
              <Tab selected>Foo</Tab>
              <Tab>Bar</Tab>
              <Tab>Baz</Tab>
            </Tabs>
          }
        </div>
        <PageHeaderGlobal>
          {search_yn === 'search_y' && navigation_type_picker !== 'horizontal' && <SearchInput type="search" placeholder="Search..." />}
          {navigation_type_picker !== 'horizontal' && 
            <PopupAvatar alt="profile pic" src="https://www.placebear.com/100/100" size={24} />
          }
        </PageHeaderGlobal>
      </PageHeader>

      <TableWrapper>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>
              Name
            </TableHeaderCell>
            {attributes?.map( attribute => {
              return <TableHeaderCell key={attribute.id}>
                {attribute.title}
              </TableHeaderCell>
            })}
          </TableRow>
        </TableHeader>

        <TableBody>
          {fakeItems.map(fi => {
            return <TableRow key={fi}>
              <TableCell $big={big}>
                My item
              </TableCell>
              {attributes?.map( attribute => {
                const { PreviewComponent } = AttributeTypes.find(at => at.id === attribute.type) || AttributeTypes.find(at => at.id === 'attr_type_unknown')
                return <TableCell key={attribute.id} $big={big}>
                  <PreviewComponent />
                </TableCell>
              })}
              {/* <ContentBox $small={!big} /> */}
            </TableRow>
          })}
        </TableBody>
      </TableWrapper>
      
      { list_size === 'more' &&
        <Pagination>« 1 2 3 4 5 »</Pagination>
      }

    </AppMain>
  </AppBody>
}

const Tabs = styled.div`
  margin: .5rem 0 -.5rem 0;
`
const Tab = styled.div`
  margin: 0 .5rem -1px 0;
  padding: .5rem .25rem;
  display: inline-block;
  border-bottom: 3px solid;
  border-bottom-color: ${p => p.selected ? 'black' : 'transparent'};
`
const Pagination = styled.div`
  margin: 1rem 0;
  text-align: center;
`

const TableWrapper = styled.table`
  width: 100%;
`
const TableHeader = styled.thead`
`
const TableHeaderCell = styled.th`
  text-align: left;
  font-weight: 600;
  font-size: var(--s);
`
const TableBody = styled.tbody`
`
const TableRow = styled.tr`
`
const TableCell = styled.td`
  padding: ${p => p.$big ? '.5rem 0' : '.5rem 0'};
  border-top: 1px solid var(--hairline);
`

export default PreviewLists
