import produce from 'immer'
import { customAlphabet } from 'nanoid/non-secure'

import {
  DialogPortal,
  DialogOverlay,
  DialogContent,
  DialogTitle,
  DialogClose,
} from '../../../components/Dialog'

const nanoid = customAlphabet('1234567890abcdef', 5)

const ListBuilderModal = ({
  EditorForm,
  item,
  itemType,
  close,
  formData,
  saveFormData,
  currentCollectionId,
  isDataPage,
  question,
  answerGroupId,
}) => {

  const save = data => {
    const newData = produce(formData, formDataDraft => {
      let questionData
      if (!isDataPage) {
        questionData = formDataDraft[question.id]
      } else {
        const collectionIndex = formDataDraft.collections.findIndex(c => c.id === currentCollectionId)
        questionData = formDataDraft.collections[collectionIndex][question.id]
      }

      if (item) {
        const group = questionData.find(ag => ag.id === answerGroupId)
        const itemIndex = group.items.findIndex(i => i.id === data.id)
        group.items[itemIndex] = data
      } else {
        const group = questionData.find(ag => ag.id === answerGroupId)
        group.items.push({
          id: nanoid(),
          ...data,
        })
      }
    })
    saveFormData(newData)
    close()
  }

  return <DialogPortal>
    <DialogOverlay />
    <DialogContent>
      <DialogTitle>{item ? `Edit ${itemType}` : `New ${itemType}`}</DialogTitle>
      <DialogClose>✕</DialogClose>
      <EditorForm item={item} close={close} save={save} />
    </DialogContent>
  </DialogPortal>
}

export default ListBuilderModal
