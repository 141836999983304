import { useState } from 'react'
import { useNavigate, NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import produce from 'immer'
import { customAlphabet } from 'nanoid/non-secure'

import { CollectionDefaults } from '../previews/data/collection_defaults'
import PageGroups from '../../data/pageGroups'
import Pages from '../../data/pages'
import groupByArray from '../../helpers/groupBy'
import {
  AccordionRoot,
  AccordionItem,
  AccordionHead,
  AccordionBody,
} from '../../components/Accordion'

const nanoid = customAlphabet('1234567890abcdef', 5)

const USER_PAGE_GROUP_ID = 'users'

const PanelNav = ({
  pages,
  currentPage,
  closeOnClick,
  setMenuIsOpen,
  formData,
  saveFormData,
  setIsPurchasing,
}) => {
  const userPages = pages.filter(p => !p.dataPage && p.pageGroup === USER_PAGE_GROUP_ID)
  const dataPages = pages.filter(p => p.dataPage)
  const otherPages = pages.filter(p => !p.dataPage && p.pageGroup !== USER_PAGE_GROUP_ID)
  const otherPagesGrouped = groupByArray(otherPages, 'pageGroup')

  const [ currentTabId, setCurrentTabId ] = useState(currentPage?.pageGroup)
  
  const navigate = useNavigate()

  const {
    collections,
  } = formData

  // TODO - MAKE A CREATE COLLECTION ACTION BECAUSE THIS IS DUPED IN WIZARD
  const addCollection = () => {
    const newValues = CollectionDefaults.find(cd => cd.id === 'things')
    const id = nanoid()
    saveFormData(produce(formData, formDataDraft => {
      formDataDraft.collections.push({
        ...newValues,
        id,
      })
    }))
    const firstPage = Pages.filter(p => p.dataPage)[0]
    const route = `/build/${firstPage.id}/${id}`
    navigate(route)
    setCurrentTabId(id)
  }

  const onAccordionClick = value => {
    if (value === '') {
      setCurrentTabId(null)
      return
    }
    let route
    const clickedPageGroup = PageGroups[value]
    if (clickedPageGroup) {
      const firstPage = Pages.filter(p => p.pageGroup === value)[0]
      route = `/build/${firstPage.id}`
    }
    const clickedCollection = formData.collections?.find(c => c.id === value)
    if (clickedCollection) {
      const firstPage = Pages.filter(p => p.dataPage)[0]
      route = `/build/${firstPage.id}/${clickedCollection.id}`
    }
    navigate(route)
    setCurrentTabId(value)
  }

  const userPageGroup = PageGroups[USER_PAGE_GROUP_ID]
  
  return <PanelNavWrapper>

    <PanelNavTop
      type="single"
      value={currentTabId}
      onValueChange={onAccordionClick}
      collapsible={true}
    >
      <AccordionItem key={USER_PAGE_GROUP_ID} value={USER_PAGE_GROUP_ID}>
        <AccordionHead>
          <MainNavItem>
            <span className="iconify" data-icon={`mdi-${userPageGroup.icon}`} />
            <div>{userPageGroup.title}</div>
          </MainNavItem>
        </AccordionHead>
        <AccordionBody isShown={currentTabId === USER_PAGE_GROUP_ID}>
          {userPages.map(page => {
            const { id, title } = page
            return <SubNavItem
              key={id}
              to={`/build/${id}`}
              onClick={() => closeOnClick && setMenuIsOpen(false) }
            >
              <div>{title}</div>
            </SubNavItem>
          })}
        </AccordionBody>
      </AccordionItem>
      
      {collections && collections.map(collection => {
        return <AccordionItem key={collection.id} value={collection.id}>
          <AccordionHead>
            <MainNavItem>
              <span className="iconify" data-icon={`mdi-format-list-bulleted`} />
              <div>{collection.collection_name_plural}</div>
            </MainNavItem>
          </AccordionHead>
          <AccordionBody isShown={currentTabId === collection.id}>
            {dataPages.map(page => {
              const { id, title } = page
              return <SubNavItem
                key={id}
                to={`/build/${id}/${collection.id}`}
                onClick={() => closeOnClick && setMenuIsOpen(false) }
              >
                <div>{title}</div>
              </SubNavItem>
            })}
          </AccordionBody>
        </AccordionItem>
      })}

      <PanelNavItemLink onClick={addCollection}>+ Add data</PanelNavItemLink>

      <PanelNavHr />

      {otherPagesGrouped.map(pageGroup => {
        const { title, icon } = PageGroups[pageGroup.key]
        return <AccordionItem key={pageGroup.key} value={pageGroup.key}>
          <AccordionHead>
            <MainNavItem>
              <span className="iconify" data-icon={`mdi-${icon}`} />
              <div>{title}</div>
            </MainNavItem>
          </AccordionHead>
          <AccordionBody isShown={currentTabId === pageGroup.key}>
            {pageGroup.values.map(page => {
              const { id, title } = page
              return <SubNavItem
                key={id}
                to={`/build/${id}`}
                onClick={() => closeOnClick && setMenuIsOpen(false) }
              >
                <div>{title}</div>
              </SubNavItem>
            })}
          </AccordionBody>
        </AccordionItem>
      })}
    </PanelNavTop>

    <PanelNavBottom>
      <MainNavItem onClick={() => setIsPurchasing(true)}>
        <span className="iconify" data-icon={`mdi-star-shooting-outline`} />
        <div>Upgrade</div>
      </MainNavItem>
      <MainNavItem as="a" target="_blank" href={`mailto:sam@directedworks.com`}>
        <span className="iconify" data-icon={`mdi-lifebuoy`} />
        <div>Help &amp; support</div>
      </MainNavItem>
    </PanelNavBottom>

  </PanelNavWrapper>
}

const PANEL_NAV_PADDING = '2rem'

const PanelNavWrapper = styled.div`
  display: grid;
  grid-template-rows: minmax(0, auto) max-content;
  align-content: space-between;
  height: 100%;
`
const PanelNavTop = styled(AccordionRoot)`
  overflow: auto;
  padding-bottom: ${PANEL_NAV_PADDING};
`
const PanelNavBottom = styled.div`
  background: #ffffffb8;
  position: relative;

  &:after {
    content: '';
    display: block;

    position: absolute;
    height: ${PANEL_NAV_PADDING};
    top: -${PANEL_NAV_PADDING};
    left: 0;
    right: 0;

    background: linear-gradient(hsl(0 0% 100% / 0), hsl(0 0% 100% / 1));
    pointer-events: none;
  }
`

// top level items
const MainNavItem = styled.button`
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: .5rem;
  align-items: center;

  padding: .5rem 1rem .5rem 1rem;
  width: 100%;

  appearance: none;
  background: transparent;
  border: none;
  color: var(--text-primary);

  text-decoration: none;
  font-family: inherit;
  font-size: var(--m);
  font-weight: 500;
  text-align: left;
  cursor: pointer;

  &:hover {
    background: var(--hover);
  }
`

// sub nav items
const SubNavItem = styled(NavLink)`
  /* display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: .5rem;
  align-items: center; */

  display: block;
  width: 100%;
  padding: .5rem 1rem .5rem 2.5rem;

  color: var(--text-primary);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: var(--hover);
  }
  &.active {
    font-weight: bold;
    /* background: var(--background-item); */
  }
`

// links

const PanelNavItemLink = styled.a`
  display: block;
  cursor: pointer;
  padding: .25rem 0 .25rem 2rem;
  text-decoration: none;
  color: var(--text-primary);
  font-size: var(--s);
  opacity: .7;
  
  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
`
const PanelNavHr = styled.hr`
  margin: 1rem;
`

export default PanelNav
