const PreviewAttrCode = ({ $large }) => {
  if ($large) {
    return <code>
      sgdgsdg seg sg s
      dsfg sdfg sdfg sger gserg 
      sgsergserg seg s
      g serg segs erg serg 
    </code>
  }
  return <code>sfg sdfg sdfg sdfg sd</code>
}

export default PreviewAttrCode
