import { Routes, Route, Navigate } from 'react-router-dom'
import Pages from '../data/pages'
import Panel from './panel/Panel'
import Wizard from './Wizard'
import Prototype from './Prototype'

const firstNonWizardNonDataPage = Pages.filter(p => !p.inWizard && !p.dataPage)[0]

const Router = () => {

  return <Routes>
    <Route path="/build/:currentPageId/:currentCollectionId" element={<Panel />} />
    <Route path="/build/:currentPageId" element={<Panel />} />
    <Route path="/build" element={<Navigate to={`/build/${firstNonWizardNonDataPage.id}`} />} />

    <Route path="/prototype" element={<Prototype />} />

    <Route path="/new" element={<Wizard />} />
    <Route path="/" element={<Navigate to="/new" />} />

    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
}

export default Router
