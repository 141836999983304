import OpenColor from 'open-color/open-color.json'

const {
  // black,
  white,
  gray,
  red,
  pink,
  grape,
  // violet,
  indigo,
  blue,
  // cyan,
  teal,
  green,
  lime,
  yellow,
  orange,
} = OpenColor

export const Themes = {
  gray: {
    backgroundBrand: gray[7],
    lightBrand:      gray[0],
    darkBrandColor:  gray[8],
    textContrast:    gray[1],
    textBrand:       gray[9],
    textPrimary:     gray[9],
    textSecondary:   gray[7],
    textTertiary:    gray[5],
    buttonBg:        blue[6],
    buttonText:      white,
    hairline:        gray[2],
  },
  red: {
    backgroundBrand: red[7],
    lightBrand:      red[0],
    darkBrandColor:  red[8],
    textContrast:    red[1],
    textBrand:       red[8],
    textPrimary:     gray[9],
    textSecondary:   gray[7],
    textTertiary:    gray[5],
    buttonBg:        blue[6],
    buttonText:      white,
    hairline:        gray[2],
  },
  pink: {
    backgroundBrand: pink[7],
    lightBrand:      pink[0],
    darkBrandColor:  pink[8],
    textContrast:    pink[1],
    textBrand:       pink[8],
    textPrimary:     gray[9],
    textSecondary:   gray[7],
    textTertiary:    gray[5],
    buttonBg:        blue[6],
    buttonText:      white,
    hairline:        gray[2],
  },
  purple: {
    backgroundBrand: grape[7],
    lightBrand:      grape[0],
    darkBrandColor:  grape[8],
    textContrast:    grape[1],
    textBrand:       grape[8],
    textPrimary:     gray[9],
    textSecondary:   gray[7],
    textTertiary:    gray[5],
    buttonBg:        blue[6],
    buttonText:      white,
    hairline:        gray[2],
  },
  indigo: {
    backgroundBrand: indigo[7],
    lightBrand:      indigo[0],
    darkBrandColor:  indigo[8],
    textContrast:    indigo[1],
    textBrand:       indigo[8],
    textPrimary:     gray[9],
    textSecondary:   gray[7],
    textTertiary:    gray[5],
    buttonBg:        blue[6],
    buttonText:      white,
    hairline:        gray[2],
  },
  blue: {
    backgroundBrand: blue[7],
    lightBrand:      blue[0],
    darkBrandColor:  blue[8],
    textContrast:    blue[1],
    textBrand:       indigo[8],
    textPrimary:     gray[9],
    textSecondary:   gray[7],
    textTertiary:    gray[5],
    buttonBg:        blue[6],
    buttonText:      white,
    hairline:        gray[2],
  },
  aqua: {
    backgroundBrand: teal[7],
    lightBrand:      teal[0],
    darkBrandColor:  teal[8],
    textContrast:    teal[1],
    textBrand:       blue[8],
    textPrimary:     gray[9],
    textSecondary:   gray[7],
    textTertiary:    gray[5],
    buttonBg:        blue[6],
    buttonText:      white,
    hairline:        gray[2],
  },
  green: {
    backgroundBrand: green[7],
    lightBrand:      green[0],
    darkBrandColor:  green[8],
    textContrast:    green[1],
    textBrand:       green[8],
    textPrimary:     gray[9],
    textSecondary:   gray[7],
    textTertiary:    gray[5],
    buttonBg:        blue[6],
    buttonText:      white,
    hairline:        gray[2],
  },
  lime: {
    backgroundBrand: lime[7],
    lightBrand:      lime[0],
    darkBrandColor:  lime[8],
    textContrast:    lime[1],
    textBrand:       green[8],
    textPrimary:     gray[9],
    textSecondary:   gray[7],
    textTertiary:    gray[5],
    buttonBg:        blue[6],
    buttonText:      white,
    hairline:        gray[2],
  },
  yellow: {
    backgroundBrand: yellow[7],
    lightBrand:      yellow[0],
    darkBrandColor:  yellow[8],
    textContrast:    yellow[1],
    textBrand:       orange[8],
    textPrimary:     gray[9],
    textSecondary:   gray[7],
    textTertiary:    gray[5],
    buttonBg:        blue[6],
    buttonText:      white,
    hairline:        gray[2],
  },
  orange: {
    backgroundBrand: orange[7],
    lightBrand:      orange[0],
    darkBrandColor:  orange[8],
    textContrast:    orange[1],
    textBrand:       orange[8],
    textPrimary:     gray[9],
    textSecondary:   gray[7],
    textTertiary:    gray[5],
    buttonBg:        blue[6],
    buttonText:      white,
    hairline:        gray[2],
  },
}
