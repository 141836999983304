import { useState, useContext } from 'react'
import Payment from './Payment'
import styled from 'styled-components/macro'

import { AppContext } from './App'
import {
  DialogRoot,
  DialogPortal,
  DialogOverlay,
  DialogContent,
  DialogClose,
} from '../components/Dialog'
import SignupForm from './marketing/SignupForm'

const Purchase = ({
  forceOpen,
}) => {

  const {
    formData,
    isPurchasing,
    setIsPurchasing,
  } = useContext(AppContext)

  const [ isPaying, setIsPaying ] = useState(false)

  return <>
    <DialogRoot
      id={'purchasing'}
      open={isPurchasing || forceOpen}
      onOpenChange={() => !forceOpen && setIsPurchasing(!isPurchasing)}
      modal={true}
    >
      <DialogPortal>
        <DialogOverlay />
        <DialogContent width="1000px">
          {!forceOpen &&
            <DialogClose>✕</DialogClose>
          }

          {/* <DialogTitle>Buy this design</DialogTitle> */}

          {/* { manualErrors.length > 0 &&
            <div style={{color: 'red'}}>Please fix errors before purchasing</div>
          } */}

          {/* <p style={{fontStyle: 'italic'}}>Note: your configuration is currently not saved. Closing your browser will reset your data.</p> */}

          <PaymentColumns>

            <div>
              <p>Coming soon</p>
              <h2>Subscribe</h2>

              <p>Bring your vision to life with an interactive design prototype.</p>
              {/* <ul> */}
                {/* <li>A <strong>full UX design</strong> for your app as a downloadable, sharable Figma file (.fig)</li> */}
                {/* <li>All the details: error states, empty states, onboarding, settings, and more</li> */}
                {/* <li>A custom-generated <strong>design system</strong> of branded UI components to make future design consistent and easy</li> */}
              {/* </ul> */}
              <p>You'll also receive:</p>
              <ul>
                <li>Downloadable wireframes of every page</li>
                <li>Bundled fonts, icon packs, and all other necessary design assets</li>
                <li>Notes for engineers so they build everything to spec</li>
                <li>Premium video guides to help you make edits</li>
                {/* <li>Instructions to help engineers install fonts and export colors</li> */}
                {/* <li>Guidelines for integrating your design with Tailwind, Material Design, and other popular UI libraries</li> */}
                {/* <li>Unlimited edits and re-downloads in Reflow for an entire year</li> */}
                {/* <li>Access to our network of expert freelance UX designers if you need to make custom changes</li> */}
              </ul>

              <hr />

              <p><strong>Previews are in private beta</strong></p>

              <p>Join the waitlist for early access</p>

              {/* <p>Special launch price</p>
              <Price><strike>$99</strike>{' '}<strong>$49</strong>/mo</Price> */}

              {/* <p>Hiring an experienced designer to build an entire application would normally cost $20k-$100k - <strong>that's 98% off</strong></p> */}

              {/* <SmallText>
                Comes with a 30-day money-back guarantee.
                See Terms and Conditions for details.
              </SmallText> */}

              <SignupForm $full />
              {/* <Button $wide onClick={() => setIsPaying(true)} autoFocus>Buy now</Button> */}

            </div>

            <div>
              <Carousel />
            </div>

          </PaymentColumns>

          <PromoBanner>
            Need designs now? Hire a full-time, dedicated UX pro. Fully custom designs for a single flat fee.
            {' '}
            <PromoBannerLink href="https://sampiercelolla.com" target="_blank">Learn more</PromoBannerLink>
          </PromoBanner>

        </DialogContent>
      </DialogPortal>
    </DialogRoot>

    <Payment
      formData={formData}
      isPaying={isPaying}
      setIsPaying={setIsPaying}
    />

  </>
}

const PaymentColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 5rem;
  grid-column-gap: 3rem;
  /* margin-top: 2rem; */

  @media(max-width: 700px) {
    grid-template-columns: auto;
  }
`
// const Price = styled.p`
//   font-size: var(--l);
//   margin: 0 0 1rem;
// `
// const SmallText = styled.p`
//   font-size: var(--s);
//   color: var(--text-secondary);
//   margin: 0 0 1rem;
// `
const Carousel = styled.div`
  background: var(--background-item);
  border-radius: 10px;
  height: 100%;
  width: 100%;
`
const PromoBanner = styled.div`
  border-top: 1px solid var(--hairline);
  padding: 2rem;
  margin: 1rem -2rem -2rem;
  text-align: center;
  font-size: var(--s);
`
const PromoBannerLink = styled.a`
  color: inherit;
`

export default Purchase
