import styled from 'styled-components/macro'

import getCollectionName from '../../helpers/getCollectionName'
import ActionsMenu from './components/ActionsMenu'
import {
  FloatingPage,
  PageTitle,
} from '../../styles/Previews'

const PreviewActions = ({ context }) => {
  return <FloatingPage>

    <PageTitle>My {getCollectionName(context, {})}</PageTitle>

    {/* <ButtonRow>
      <ActionMenuIcon>
        <svg style={{width:'24px',height:'24px'}} viewBox="0 0 24 24">
          <path fill="currentColor" d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z" />
        </svg>
      </ActionMenuIcon>
    </ButtonRow> */}

    <MenuPositionHelper>
      <ActionsMenu context={context} />
    </MenuPositionHelper>

  </FloatingPage>
}

// const ButtonRow = styled.div`
//   display: flex;
//   align-items: center;
// `
// const ActionMenuIcon = styled.span`
//   display: flex;
//   align-items: center;
//   justify-content: center;
  
//   border-radius: 100px;
//   width: 24px;
//   height: 24px;
//   background: var(--background-item);

//   svg {
//     opacity: .5;
//     width: 18px !important;
//     height: 18px !important;
//   }
// `

const MenuPositionHelper = styled.div`
  position: absolute;
  top: -20px;
  right: 40px;
`

export default PreviewActions
