import { useContext } from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet'

import { AppContext } from './App'
import Preview from './previews/Preview'
import Purchase from './Purchase'
// import { Button } from '../styles/Buttons'
import PreviewDashboard from './previews/PreviewDashboard'

const Prototype = () => {

  const {
    formData,
  } = useContext(AppContext)
  
  return <>

    <Helmet>
      <title>{formData.app_name || 'MyApp'} preview</title>
    </Helmet>

    <Purchase forceOpen />

    <PrototypeWrapper>

      {/* <PrototypeHeader>
        <PrototypeHeaderLogo>
          <NameWrapper>
            <div>{formData.app_name || 'Untitled'}</div>
          </NameWrapper>
        </PrototypeHeaderLogo>
        <PrototypeHeaderRight>

          <Button
            $small
            $secondary
          >
            <span className="iconify" data-icon="mdi-share" />
            Share
          </Button>
        </PrototypeHeaderRight>
      </PrototypeHeader> */}
      
      <Preview
        // currentPage={currentPage}
        formData={formData}
        // currentCollectionId={currentCollectionId}
        // isDataPage={isDataPage}
        forceComponent={PreviewDashboard}
      />

    </PrototypeWrapper>
  </>
}

const PrototypeWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  height: 100vh;
`
// const PrototypeHeader = styled.div`
//   display: grid;
//   grid-template-columns: auto max-content;
//   grid-gap: 1rem;
//   align-items: center;

//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;

//   background: hsl(0deg 0% 0% / 80%);
//   color: white;

//   padding: .666rem 1rem;
//   z-index: 5000;
// `
// const PrototypeHeaderLogo = styled.div`
//   display: grid;
//   grid-template-columns: max-content max-content;
//   grid-gap: 1rem;
//   align-items: center;
// `
// const PrototypeHeaderRight = styled.div`
//   display: flex;
//   align-items: center;
// `
// const PrototypeBody = styled.div`
//   display: grid;
//   grid-template-columns: 200px 3fr 3fr;

//   @media(max-width: 600px) {
//     grid-template-columns: auto;
//     grid-template-rows: 200px auto; /* height of preview wrapper */
//   }
// `
// const NameWrapper = styled.div`
//   display: grid;
//   align-items: center;
//   grid-template-columns: max-content max-content;
// `

export default Prototype
