/*
  
  THE QUESTIONS

  Every time you add/remove questions here, do a global search for the question "id"
  and make sure all related logic is updated!

*/

import * as yup from 'yup'

import QuestionBigText from             '../views/questions/QuestionBigText'
import QuestionBuilder from             '../views/questions/QuestionBuilder'
import QuestionDeleteCollection from    '../views/questions/QuestionDeleteCollection'
import QuestionIntro from               '../views/questions/QuestionIntro'
import QuestionCheckboxes from          '../views/questions/QuestionCheckboxes'
// import QuestionCheckboxPills from       '../views/questions/QuestionCheckboxPills'
import QuestionRadioPills from          '../views/questions/QuestionRadioPills'
import QuestionSelect from              '../views/questions/QuestionSelect'
import QuestionRadio from               '../views/questions/QuestionRadio'
import QuestionToggle from              '../views/questions/QuestionToggle'

import AttributeForm from '../views/questions/builder_forms/AttributeForm'
// import PageForm from '../views/questions/builder_forms/PageForm'

import getCollectionName from '../helpers/getCollectionName'
import getUsersLabel from '../helpers/getUsersLabel'

const Questions = [
  {
    page: `wizard_welcome`,
    id: `welcome_intro`,
    name: `Welcome intro`,
    title: () => `Ahhh... that new app smell ☺️`,
    description: () => <>
      <p>
        Let's build you an awesome app!
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `wizard_welcome`,
    id: `app_name`,
    name: `App name`,
    title: () => `What's the name of your app?`,
    example: `Example: TikTok, Quickbooks`,
    QuestionComponent: QuestionBigText,
    yup_validation: yup.string().required(),
    default: () => `MyApp`, // TODO - use signup email address to pre-fill here (but don't delete, it's annoying to *have* to type something)
  },
  {
    page: `wizard_platform`,
    id: `app_platform`,
    name: `Platform`,
    title: () => `What kind of app is it?`,
    description: () => `(You can change these answers later)`,
    QuestionComponent: QuestionRadio,
    yup_validation: yup.string().required(),
    default: () => `web`,
    choices: [
      {
        id: `web`,
        title: `It's a website or web app`,
        description: `Includes desktop, laptop, and tablet sizes`,
      },
      {
        id: `native`,
        title: `It's a native mobile app (coming soon)`,
        description: `iOS and Android tablet and phone sizes`,
        disabled: true,
      },
    ],
  },
  {
    page: `wizard_type`,
    id: `app_type`,
    name: `App type`,
    title: context => `What's the purpose of ${context.app_name || 'your app'}?`,
    description: context => <>
      <p>
        {context.app_name || 'This app'} will help users...
      </p>
    </>,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => [],
    choices: [
      {
        id: `social`,
        title: `Connect to other users`,
        // example: `Example: LinkedIn`,
      },
      {
        id: `events`,
        title: `Manage events`,
        // example: `Example: Google Calendar`,
      },
      {
        id: `analytics`,
        title: `Analyze data`,
        // example: `Example: Stripe, Quickbooks`,
      },
      {
        id: `documents`,
        title: `Manage documents`,
        // example: `Example: Dropbox, Google Docs`,
      },
      {
        id: `tasks`,
        title: `Assign tasks`,
        // example: `Example: Asana, CRM`,
      },
      {
        id: `ecommerce`,
        title: `Buy things`,
        // example: `Example: Ebay`,
      },
      {
        id: `media`,
        title: `Browse video/audio`,
        // example: `Example: YouTube`,
      },
      {
        id: `other`,
        title: `Something else`,
      },
    ],
  },
  {
    page: `wizard_colors`,
    id: `wizard_color`,
    name: `Wizard color`,
    title: () => `What color should we use?`,
    description: () => `Pick a color, any color...`,
    QuestionComponent: QuestionRadioPills,
    yup_validation: yup.string().required(),
    default: () => `blue`,

    // TODO - keep in sync with color_primary!!
    choices: [
      {
        id: `gray`,
        title: `Gray`,
        color: `gray`,
      },
      {
        id: `red`,
        title: `Red`,
        color: `red`,
      },
      {
        id: `pink`,
        title: `Pink`,
        color: `pink`,
      },
      {
        id: `purple`,
        title: `Purple`,
        color: `purple`,
      },
      {
        id: `indigo`,
        title: `Indigo`,
        color: `indigo`,
      },
      {
        id: `blue`,
        title: `Blue`,
        color: `blue`,
      },
      {
        id: `aqua`,
        title: `Aqua`,
        color: `aqua`,
      },
      {
        id: `green`,
        title: `Green`,
        color: `green`,
      },
      {
        id: `lime`,
        title: `Lime`,
        color: `lime`,
      },
      {
        id: `yellow`,
        title: `Yellow`,
        color: `yellow`,
      },
      {
        id: `orange`,
        title: `Orange`,
        color: `orange`,
      },
    ],
  },
  {
    page: `user_basics`,
    id: `user_basics_intro`,
    name: `User basics intro`,
    title: () => `User basics`,
    description: () => <>
      <p>
        To build a great user experience, we need to know a little about the people who will use your app.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `user_basics`,
    id: `users_label`,
    name: `Users label`,
    title: () => `What do you call your users?`,
    example: `Example: A healthcare site might call their users "patients", but a gaming site might call their users "players"`,
    QuestionComponent: QuestionBigText,
    yup_validation: yup.string().required(),
    default: () => `Users`,
  },
  {
    page: `user_data`,
    id: `user_data_intro`,
    name: `User data intro`,
    title: () => `User data`,
    description: context => <>
      <p>
        What kind of information would you like about your {getUsersLabel(context, {})}?
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `user_data`,
    id: `user_attributes`,
    name: `User attributes`,
    title: context => `What else do you need to know about ${getUsersLabel(context, {})}?`,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => [],
    comingSoon: true,
    choices: [
      {
        id: `phone`,
        title: `Phone number`,
        description: `Useful if you plan to do direct sales with enterprise customers, but sometimes seen as creepy for consumer apps`,
      },
      {
        id: `birthday`,
        title: `Birthday`,
        description: `Important for apps that limit users by age`,
      },
      {
        id: `mailing_address`,
        title: `Mailing address`,
        description: `Useful if you need to send packages`,
      },
    ],
  },
  {
    page: `user_features`,
    id: `user_features_intro`,
    name: `User features intro`,
    title: () => `User features`,
    description: context => <>
      <p>
        Lots of apps have user profiles, or pages that let {getUsersLabel(context, {})} see information about each other.
      </p>
      <p>
        This is especially important for collaborative apps, or apps with a social component.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `user_features`,
    id: `teams_yn`,
    name: `Teams`,
    title: context => `Will ${getUsersLabel(context, {})} sign up as part of a team?`,
    description: context => `${getUsersLabel(context, {capital: true})} will share a workspace and can invite others. If you charge for your app, they will be billed as a team.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `teams_n`,
    choices: [
      {
        id: `teams_n`,
      },
      {
        id: `teams_y`,
      },
    ],
  },
  {
    page: `user_features`,
    id: `profiles_yn`,
    name: `Show profiles`,
    title: () => `Show public profiles for each user?`,
    description: context => `For collaborative apps, this helps ${getUsersLabel(context, {})} get a sense of who else is on the platform`,
    QuestionComponent: QuestionToggle,
    default: () => `profiles_n`,
    choices: [
      {
        id: `profiles_n`,
      },
      {
        id: `profiles_y`,
      },
    ],
  },
  {
    page: `user_features`,
    id: `profile_attrs`,
    name: `Profile content`,
    title: () => `What information will appear on their profile?`,
    description: () => `We recommend you collect a few other pieces of information.`,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => [`profile_pic`, `bio`],
    displayTest: context => context.profiles_yn === `profiles_y`,
    choices: [
      {
        id: `profile_pic`,
        title: `Profile pic`,
        description: `Recommended`,
      },
      {
        id: `bio`,
        title: `Bio`,
        description: `Short description of themselves`,
      },
      {
        id: `social`,
        title: `Links to social media profiles`,
        description: `Helps other users find them online`,
      },
    ],
  },
  {
    page: `user_features`,
    id: `follow_users_yn`,
    name: `Followers`,
    title: context => `Let people follow other ${getUsersLabel(context, {})}?`,
    description: context => `This will let ${getUsersLabel(context, {})} keep tabs on any public content that other ${getUsersLabel(context, {})} post`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `follow_users_n`,
    displayTest: context => context.profiles_yn === `profiles_y`,
    choices: [
      {
        id: `follow_users_n`,
      },
      {
        id: `follow_users_y`,
      },
    ],
  },
  {
    page: `user_features`,
    id: `messaging_yn`,
    name: `Messaging`,
    title: context => `Let people send private messages to other ${getUsersLabel(context, {})}?`,
    description: context => `${getUsersLabel(context, {capital: true})} will have an inbox and get notifications of new messages`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `messaging_n`,
    displayTest: context => context.profiles_yn === `profiles_y`,
    choices: [
      {
        id: `messaging_n`,
      },
      {
        id: `messaging_y`,
      },
    ],
  },
  {
    page: `user_features`,
    id: `online_status_yn`,
    name: `Online status`,
    title: context => `Show when ${getUsersLabel(context, {})} are online?`,
    description: context => `Useful for real-time apps where it's important to know if other ${getUsersLabel(context, {})} are online right now`,
    QuestionComponent: QuestionToggle,
    default: () => `online_status_n`,
    displayTest: context => context.profiles_yn === `profiles_y`,
    choices: [
      {
        id: `online_status_n`,
      },
      {
        id: `online_status_y`,
      },
    ],
  },
  {
    page: `user_features`,
    id: `user_name_yn`,
    name: `User name`,
    title: context => `Let ${getUsersLabel(context, {})} set a username?`,
    description: context => `Good for social sites where ${getUsersLabel(context, {})} may want to contribute under an alias`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `user_name_n`,
    displayTest: context => context.profiles_yn === `profiles_y`,
    choices: [
      {
        id: `user_name_n`,
      },
      {
        id: `user_name_y`,
      },
    ],
  },
  {
    page: `data_name`,
    id: `data_name_intro`,
    name: `Data name intro`,
    title: () => `Collection basics`,
    description: () => <>
      <p>
        A collection is like a tab in a spreadsheet. For example, you might have a collection called "People", "Events", "Tasks", "Places" etc.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `data_name`,
    id: `collection_name_plural`,
    name: `Collection name`,
    title: () => `What do you want to call this collection?`,
    example: `Example: Facebook has "friends", Instagram has "posts", Spotify has "songs" or "albums"`,
    QuestionComponent: QuestionBigText,
    yup_validation: yup.string().required(),
    default: () => `Items`,
  },
  {
    page: `data_name`,
    id: `collection_icon`,
    name: `Collection icon`,
    title: () => `What icon should we use to represent this collection?`,
    description: () => `This will appear in the navigation and in search results`,
    QuestionComponent: QuestionBigText,
    yup_validation: yup.string().required().min(1),
    default: () => `square`,
    comingSoon: true,
  },
  // {
  //   page: `data_name`,
  //   id: `collection_type`,
  //   name: `Collection type`,
  //   title: () => `What kind of thing is this collection?`,
  //   description: () => `We'll use your answer to pre-fill some data for you, but you can change anything later`,
  //   QuestionComponent: QuestionSelect,
  //   yup_validation: yup.string().required(), // todo limit to the options here, ie: () => this.choices.map(id => id)
  //   default: () => `collection_type_custom`,
  //   choices: [
  //     {
  //       id: `collection_type_custom`,
  //       title: `None of these`,
  //       description: `Start from scratch`,
  //     },
  //     {
  //       id: `collection_type_person`,
  //       title: `Person`,
  //       description: `Users, people, employees, friends, etc`,
  //     },
  //     {
  //       id: `collection_type_products`,
  //       title: `Product`,
  //       description: `Apparel, home goods, digital downloads, etc`,
  //     },
  //     {
  //       id: `collection_type_files`,
  //       title: `Files`,
  //       description: `Files, reports, data, etc`,
  //     },
  //     {
  //       id: `collection_type_media`,
  //       title: `Media`,
  //       description: `Song, audio, video, podcast, VR, etc`,
  //     },
  //   ],
  // },
  {
    page: `data_attributes`,
    id: `data_name_intro`,
    name: `Data name intro`,
    title: context => `${getCollectionName(context, { capital: true })} data`,
    description: context => <>
      <p>
        What kind of data you want to show about {getCollectionName(context, { plural: true })}?
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `data_attributes`,
    id: `item_attributes`,
    name: `Attributes`,
    QuestionComponent: QuestionBuilder,
    EditorForm: AttributeForm,
    yup_validation: yup.array().of(yup.object()), // TODO - check validation on update (not a form element), more specific object validation, prevent duplicate ids
    default: () => [
      {
        id: `most_important`,
        title: `Most important`,
        items: [
          {
            id: `location`,
            title: `Location`,
            type: `attr_type_location`,
          },
          {
            id: `timeline`,
            title: `Timeline`,
            type: `attr_type_collection`,
          },
        ],
      },
      {
        id: `less_important`,
        title: `Less important`,
        items: [
          {
            id: `created_by`,
            title: `Creator`,
            type: `attr_type_user`,
          },
          {
            id: `created_on`,
            title: `Date created`,
            type: `attr_type_date`,
          },
        ],
      },
    ],
    suggestions: [
      {
        id: `name`,
        title: `Name`,
        type: `attr_type_short_text`,
      },
      {
        id: `category`,
        title: `Category`,
        type: `attr_type_category`,
      },
      {
        id: `choice`,
        title: `Choice`,
        type: `attr_type_choice`,
      },
      {
        id: `code`,
        title: `Code`,
        type: `attr_type_code`,
      },
      {
        id: `file`,
        title: `File`,
        type: `attr_type_file`,
      },
      {
        id: `images`,
        title: `Images`,
        type: `attr_type_images`,
      },
      {
        id: `long_text`,
        title: `Description`,
        type: `attr_type_long_text`,
      },
      {
        id: `rich_text`,
        title: `Content`,
        type: `attr_type_rich_text`,
      },
      {
        id: `status`,
        title: `Status`,
        type: `attr_type_status`,
      },
      {
        id: `tags`,
        title: `Tags`,
        type: `attr_type_tags`,
      },
      {
        id: `toggle`,
        title: `On/Off`,
        type: `attr_type_toggle`,
      },
      {
        id: `other`,
        title: `Other`,
        type: `attr_type_other`,
      },
    ],
  },
  {
    page: `data_name`,
    id: `data_delete_option`,
    name: `Delete data`,
    title: context => `Delete ${getCollectionName(context, {})}`,
    QuestionComponent: QuestionDeleteCollection,
  },
  {
    page: `data_add_ons`,
    id: `data_add_ons_intro`,
    name: `Data features intro`,
    title: context => `${getCollectionName(context, {capital: true})} features`,
    description: context => <>
      <p>
        Let's look at a few common features we can add to {getCollectionName(context, {plural: true})}
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `data_add_ons`,
    id: `profile_pic_yn`,
    name: `Profile picture`,
    title: context => `Should ${getCollectionName(context, { plural: true })} have a picture?`,
    description: () => `This is a single picture that will appear when this item is in a list or being searched for`,
    example: `Example: your profile picture on facebook`,
    QuestionComponent: QuestionToggle,
    default: () => `profile_pic_n`,
    choices: [
      {
        id: `profile_pic_n`,
      },
      {
        id: `profile_pic_y`,
      },
    ],
  },
  {
    page: `data_add_ons`,
    id: `gallery_banner_pic`,
    name: `Gallery banner`,
    title: context => `Should ${getCollectionName(context, { plural: true })} have a gallery of images?`,
    description: () => `This is a large picture or set of pictures that show up large on an items page. If you want pictures but don't want them at the top of the page like this, you can also add pictures in the next step.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `no_pic`,
    choices: [
      {
        id: `no_pic`,
      },
      {
        id: `gallery_pics`,
      },
    ],
  },
  {
    page: `data_add_ons`,
    id: `share_yn`,
    name: `Sharing`,
    title: context => `Let ${getUsersLabel(context, {})} share a link to ${getCollectionName(context, { plural: true })}?`,
    description: () => `They can copy a link to paste in a message or social media`,
    QuestionComponent: QuestionToggle,
    default: () => `share_n`,
    choices: [
      {
        id: `share_n`,
      },
      {
        id: `share_y`,
      },
    ],
  },
  {
    page: `data_add_ons`,
    id: `favorite_yn`,
    name: `Favorites`,
    title: context => `Let ${getUsersLabel(context, {})} mark their favorite ${getCollectionName(context, { plural: true })}?`,
    description: () => `This will help them keep track of particular items they care about, and allow you to show popular items if you choose.`,
    QuestionComponent: QuestionToggle,
    default: () => `favorite_n`,
    choices: [
      {
        id: `favorite_n`,
      },
      {
        id: `favorite_y`,
      },
    ],
  },
  // {
  //   page: `data_add_ons`,
  //   id: `favorite_label`,
  //   name: `Favorites label`,
  //   title: () => `What should we call favorites?`,
  //   QuestionComponent: QuestionSelect,
  //   yup_validation: yup.string().required(),
  //   default: () => `favorites`,
  //   displayTest: context => context.favorite_yn === `favorite_y`,
  //   choices: [
  //     {
  //       id: `favorites`,
  //       title: `Favorites`,
  //     },
  //     {
  //       id: `likes`,
  //       title: `Likes`,
  //     },
  //     {
  //       id: `pins`,
  //       title: `Pins`,
  //     },
  //     {
  //       id: `library`,
  //       title: `Adds`,
  //     },
  //   ],
  // },
  {
    page: `data_add_ons`,
    id: `item_for_sale_yn`,
    name: `For sale`,
    title: context => `Let ${getUsersLabel(context, {})} buy ${getCollectionName(context, { plural: true })} in your app?`,
    description: () => `Good for subscriptions, products`,
    QuestionComponent: QuestionToggle,
    default: () => `item_for_sale_n`,
    choices: [
      {
        id: `item_for_sale_n`,
      },
      {
        id: `item_for_sale_y`,
      },
    ],
  },
  {
    page: `data_add_ons`,
    id: `item_ratings_reviews_yn`,
    name: `Ratings and reviews`,
    title: context => `Let ${getUsersLabel(context, {})} rate and leave reviews for ${getCollectionName(context, { plural: true })}?`,
    description: () => `Good for products and locations`,
    QuestionComponent: QuestionToggle,
    default: () => `item_ratings_reviews_n`,
    choices: [
      {
        id: `item_ratings_reviews_n`,
      },
      {
        id: `item_ratings_reviews_y`,
      },
    ],
  },
  {
    page: `data_add_ons`,
    id: `item_comments_yn`,
    name: `Comments`,
    title: context => `Let ${getUsersLabel(context, {})} leave comments on ${getCollectionName(context, { plural: true })}?`,
    description: () => `Good for social media posts`,
    QuestionComponent: QuestionToggle,
    default: () => `item_comments_n`,
    choices: [
      {
        id: `item_comments_n`,
      },
      {
        id: `item_comments_y`,
      },
    ],
  },
  {
    page: `actions`,
    id: `actions_intro`,
    name: `Actions intro`,
    title: context => `${getCollectionName(context, { capital: true})} actions`,
    description: () => `Let's look at the "verbs" of your collection. What are the things you can actually *do* to your data?`,
    example: <>
      <p>
        Example: with Facebook friends, you can "comment", "message", and "unfriend"
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `actions`,
    id: `item_actions`,
    name: `Actions`,
    title: () => `Actions`,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => ['message'],
    choices: [
      {
        id: `preview`,
        title: `Preview`,
        description: `Same as quick look?`,
      },
      {
        id: `rename`,
        title: `Rename`,
        description: `Change the name without editing it otherwise`,
      },
      {
        id: `duplicate`,
        title: `Duplicate`,
        description: `Immediately create a copy of this item`,
      },
      {
        id: `share`,
        title: `Share`,
        description: `Open a share window with lots of share options`,
      },
      {
        id: `print`,
        title: `Print`,
        description: `If there's long text attr`,
      },
      {
        id: `download`,
        title: `Download`,
        description: `Download this item`,
      },
      {
        id: `export`,
        title: `Export`,
        description: `Export as a file`,
      },
      {
        id: `report`,
        title: `Report inappropriate content`,
        description: `Let's users report inappropriate content on this item`,
      },
    ],
  },
  {
    page: `lists`,
    id: `lists_intro`,
    name: `Lists intro`,
    title: context => `Lists of ${getCollectionName(context, { plural: true })}`,
    description: () => <>
      <p>
        In any app, generally you're going to see a list of items. This page will help you format that list in the best way possible.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `lists`,
    id: `list_size`,
    name: `List size`,
    title: context => `What's the most number of ${getCollectionName(context, { plural: true })} that are likely to appear for each user?`,
    description: () => `This gives us an idea of how big to make each item in a list, and how many pages we might need to show.`,
    QuestionComponent: QuestionRadio,
    yup_validation: yup.string().required(),
    default: () => `50`,
    choices: [
      {
        id: `5`,
        title: `Just a few`,
      },
      {
        id: `50`,
        title: `About 50`,
      },
      {
        id: `more`,
        title: `500 or more`,
      },
    ],
  },
  {
    page: `lists`,
    id: `list_grouped_yn`,
    name: `List grouping`,
    title: context => `Organize ${getCollectionName(context, { plural: true })} into groups?`,
    description: () => `This can help people find what they're looking for faster.`,
    QuestionComponent: QuestionToggle,
    displayTest: context => context.currentCollection?.list_size !== `5` && context.currentCollection?.list_size !== `50`,
    default: () => `flat_list`,
    choices: [
      {
        id: `list_grouped_n`,
      },
      {
        id: `list_grouped_y`,
      },
    ],
  },
  {
    page: `editing`,
    id: `editing_intro`,
    name: `Editing intro`,
    title: context => `Editing ${getCollectionName(context, { plural: true })}`,
    description: context => <>
      <p>
        Most apps let {getUsersLabel(context, {})} create new items, either with a big form or a step-by-step process that asks {getUsersLabel(context, {})} questions.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `editing`,
    id: `editing_yn`,
    name: `Editing`,
    title: context => `Let ${getUsersLabel(context, {})} create, edit, and delete ${getCollectionName(context, { plural: true })}?`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `editing_y`,
    choices: [
      {
        id: `editing_n`,
      },
      {
        id: `editing_y`,
      },
    ],
  },
  {
    page: `editing`,
    id: `quick_add_yn`,
    name: `Creating commonness`,
    title: context => `Show a "quick add" for ${getCollectionName(context, { plural: true })}?`,
    description: context => `This will let ${getUsersLabel(context, {})} add a new ${getCollectionName(context, {})} from any page on the app. This is a good idea if adding ${getCollectionName(context, { plural: true })} is done very often?`,
    example: `Example: tasks, emails, tweets`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `quick_add_n`,
    displayTest: context => context.currentCollection?.editing_yn === `editing_y`,
    comingSoon: true,
    choices: [
      {
        id: `quick_add_n`,
      },
      {
        id: `quick_add_y`,
      },
    ],
  },
  {
    page: `editing`,
    id: `templates_yn`,
    name: `Templates`,
    title: context => `Let people choose from a list of existing ${getCollectionName(context, {})} templates?`,
    description: context => <>
      <p>
        Providing a list of {getCollectionName(context, {})} templates can make it easier and faster to create {getCollectionName(context, { plural: true })}.
      </p>
      <p>
        If most {getCollectionName(context, { plural: true })} are pretty similar, templates will save people a lot of time. Otherwise, just have {getUsersLabel(context, {})} start from scratch.
      </p>
    </>,
    QuestionComponent: QuestionToggle,
    displayTest: context => context.currentCollection?.editing_yn === `editing_y`,
    default: () => `template_n`,
    comingSoon: true,
    choices: [
      {
        id: `template_n`,
      },
      {
        id: `template_y`,
      },
    ],
  },
  // {
  //   page: `editing`,
  //   id: `confirm_page_yn`,
  //   name: `Confirm page`,
  //   title: context => `Show a confirmation page before saving a new ${getCollectionName(context, {})}?`,
  //   description: () => `This is good for things that are hard to undo once created (like events, purchases, and payments)`,
  //   QuestionComponent: QuestionToggle,
  //   yup_validation: yup.string().required(),
  //   default: () => `confirm_n`,
  //   displayTest: context => context.currentCollection?.editing_yn === `editing_y`,
  //   choices: [
  //     {
  //       id: `confirm_n`,
  //     },
  //     {
  //       id: `confirm_y`,
  //     },
  //   ],
  // },
  {
    page: `editing`,
    id: `import_yn`,
    name: `Importing api`,
    title: context => `Let ${getUsersLabel(context, {})} import ${getCollectionName(context, { plural: true })}?`,
    description: context => `This will allow ${getUsersLabel(context, {})} to upload or sync ${getCollectionName(context, { plural: true })} from a file or integration.`,
    example: `Example: importing a client list from Quickbooks or a CSV`,
    QuestionComponent: QuestionToggle,
    default: () => `import_n`,
    displayTest: context => context.currentCollection?.editing_yn === `editing_y`,
    comingSoon: true,
    choices: [
      {
        id: `import_n`,
      },
      {
        id: `import_y`,
      },
    ],
  },
  {
    page: `editing`,
    id: `edit_wizard_yn`,
    name: `Edit flow`,
    title: context => `Should people create and edit ${getCollectionName(context, { plural: true })} all at once or in steps?`,
    description: () => `Good for helping people so they don't feel intimidated. We recommend this because of how many attributes you have.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `edit_wizard_n`,
    displayTest: context => context.currentCollection?.editing_yn === `editing_y`,
    comingSoon: true,
    choices: [
      {
        id: `edit_wizard_n`,
      },
      {
        id: `edit_wizard_y`,
      },
    ],
  },
  {
    page: `editing`,
    id: `preview_item_yn`,
    name: `Preview item`,
    title: context => `Show a preview of the ${getCollectionName(context, {})} they're editing?`,
    description: () => `This is useful for showing the visual consequences of different options, especially for complex or custom-built items`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `preview_n`,
    displayTest: context => context.currentCollection?.editing_yn === `editing_y`,
    comingSoon: true,
    choices: [
      {
        id: `preview_n`,
      },
      {
        id: `preview_y`,
      },
    ],
  },
  {
    page: `editing`,
    id: `edit_autosave_yn`,
    name: `Auto-save`,
    title: context => `Auto-save changes to ${getCollectionName(context, { plural: true })} as ${getUsersLabel(context, {})} make edits?`,
    description: () => `Good for items with lots of long text; however, it makes it harder to edit an item as a "test" and change your mind.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `edit_autosave_n`,
    displayTest: context => context.currentCollection?.editing_yn === `editing_y`,
    choices: [
      {
        id: `edit_autosave_n`,
      },
      {
        id: `edit_autosave_y`,
      },
    ],
  },
  {
    page: `editing`,
    id: `archive_yn`,
    name: `Archiving`,
    title: context => `Let ${getUsersLabel(context, {})} archive ${getCollectionName(context, { plural: true })}?`,
    description: context => `This lets ${getUsersLabel(context, {})} move ${getCollectionName(context, { plural: true })} out of the way without deleting them. Good for data that is often not relevant anymore, but needs to stick around just in case.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `archive_n`,
    displayTest: context => context.currentCollection?.editing_yn === `editing_y`,
    choices: [
      {
        id: `archive_n`,
      },
      {
        id: `archive_y`,
      },
    ],
  },
  {
    page: `editing`,
    id: `trash_yn`,
    name: `Trash`,
    title: context => `Move deleted ${getCollectionName(context, {})} to the "trash"?`,
    description: context => `This lets ${getUsersLabel(context, {})} easily change their mind if they delete something by accident`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `trash_n`,
    displayTest: context => context.currentCollection?.editing_yn === `editing_y`,
    comingSoon: true,
    choices: [
      {
        id: `trash_n`,
      },
      {
        id: `trash_y`,
        consequence: `Users will be able to see deleted items in the trash. They will have 30 days to restore them before they are deleted permanently.`,
      },
    ],
  },
  {
    page: `editing`,
    id: `delete_confirm_yn`,
    name: `Confirm deletes`,
    title: context => `Double check before deleting ${getCollectionName(context, { plural: true })}?`,
    description: () => `This is a good idea for most things, especially if they would be frustrating to lose if accidentally deleted`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `delete_confirm_n`,
    displayTest: context => context.currentCollection?.editing_yn === `editing_y`,
    comingSoon: true,
    choices: [
      {
        id: `delete_confirm_n`,
      },
      {
        id: `delete_confirm_y`,
      },
    ],
  },
  {
    page: `dashboard`,
    id: `dashboard_intro`,
    name: `Dashboard intro`,
    title: () => `Dashboard`,
    description: context => <>
      <p>
        What will returning {getUsersLabel(context, {})} see when they log in?
      </p>
      <p>
        If your app has a lot of information, you may want to show them a summary on a dashboard.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `dashboard`,
    id: `dashboard_yn`,
    name: `Dashboard`,
    title: () => `Show a custom dashboard?`,
    description: context => <>
      <p>
        This is what {getUsersLabel(context, {})} will see when they first log in (or click your logo).
      </p>
    </>,
    QuestionComponent: QuestionToggle,
    default: () => `dashboard_y`, // necessary for dashboard preview in wizard
    choices: [
      {
        id: `dashboard_n`,
      },
      {
        id: `dashboard_y`,
      },
    ],
  },
  // {
  //   page: `feeds`,
  //   id: `dashboard_items`,
  //   name: `Dashboard items`,
  //   title: () => `What do you want to show on the dashboard?`,
  //   description: () => `Think about what would be most useful if they were sitting down at a desk to start their day.`,
  //   QuestionComponent: QuestionBuilder,
  //   EditorForm: WidgetForm,
  //   yup_validation: yup.array().of(yup.object()),
  //   displayTest: context => context.dashboard_yn === `dashboard_y`,
  //   default: () => [
  //     {
  //       id: `dashboard_main`,
  //       title: `Most important dashboard`,
  //       items: [
  //         {
  //           id: `stats`,
  //           title: `Statistics around what's happened lately`,
  //           example: `Example: number of deals today`,
  //         },
  //         {
  //           id: `chart`,
  //           title: `Chart`,
  //           example: `Example: growth over time`,
  //         },
  //       ],
  //     },
  //     {
  //       id: `dashboard_secondary`,
  //       title: `Less important`,
  //       items: [
  //         {
  //           id: `timeline`,
  //           title: `A timeline of recent events`,
  //           example: `Example: all new and updated items`,
  //         },
  //       ],
  //     },
  //   ],
  //   suggestions: [
  //     {
  //       id: `top`,
  //       title: `Top or popular items`,
  //       example: `Example: most popular store items today`,
  //     },
  //   ],
  // },
  {
    page: `feeds`,
    id: `feeds_intro`,
    name: `Feeds intro`,
    title: () => `Feeds`,
    description: () => <>
      <p>
        Do you want to show feeds of recent, popular, or suggested items?
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `feeds`,
    id: `feeds_items`,
    name: `Feeds`,
    title: () => `Feeds`,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => [],
    choices: [
      {
        id: `suggested`,
        title: `Suggested items`,
        description: `All items sorted by an algorithm's score of relevance for this user`,
      },
      {
        id: `popular`,
        title: `Popular items`,
        description: `The most popular items across all users in the system`,
      },
      {
        id: `recent_changes`,
        title: `Recent changes`,
        description: `The most recently changed items`,
      },
    ],
  },
  {
    page: `calendar`,
    id: `calendar_intro`,
    name: `Calendar intro`,
    title: () => `Calendar`,
    description: context => <>
      <p>
        A calendar can help your {getUsersLabel(context, {})} see all items with dates arranged by time.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `calendar`,
    id: `calendar_yn`,
    name: `calendar`,
    title: () => `Show a calendar page?`,
    description: () => `Everything with a date will appear all on one page.`,
    QuestionComponent: QuestionToggle,
    default: () => `calendar_n`,
    choices: [
      {
        id: `calendar_n`,
      },
      {
        id: `calendar_y`,
      },
    ],
  },
  {
    page: `map`,
    id: `map_intro`,
    name: `Map intro`,
    title: () => `Map`,
    description: context => <>
      <p>
        A map can help your {getUsersLabel(context, {})} see all items with locations arranged geographically.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `map`,
    id: `map_yn`,
    name: `map`,
    title: () => `Show a map page?`,
    description: () => `Everything with a location will appear all on one map.`,
    QuestionComponent: QuestionToggle,
    default: () => `map_n`,
    choices: [
      {
        id: `map_n`,
      },
      {
        id: `map_y`,
      },
    ],
  },
  {
    page: `onboarding`,
    id: `onboarding_intro`,
    name: `Onboarding intro`,
    title: () => `New user onboarding`,
    description: context => <>
      <p>
        The first-time new {getUsersLabel(context, {})} log in might be the most important part of your whole design.
      </p>
      <p>
        Let's make a great first impression--and help them learn to use {context.app_name || 'your app'}!
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `onboarding`,
    id: `onboarding_purpose_yn`,
    name: `Onboarding purpose`,
    title: context => `Ask ${getUsersLabel(context, {})} what their primary goal is?`,
    description: () => `This can help you give them custom settings or onboarding to-dos later--plus it helps you understand what kind of new users you have`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `onboarding_purpose_n`,
    choices: [
      {
        id: `onboarding_purpose_n`,
      },
      {
        id: `onboarding_purpose_y`,
      },
    ],
  },
  {
    page: `onboarding`,
    id: `onboarding_role_yn`,
    name: `Onboarding role`,
    title: context => `Ask ${getUsersLabel(context, {})} what their job title or role is?`,
    description: () => `This can help you target them for sales later`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `onboarding_role_n`,
    displayTest: context => context.teams_yn === `teams_y`,
    choices: [
      {
        id: `onboarding_role_n`,
      },
      {
        id: `onboarding_role_y`,
      },
    ],
  },
  {
    page: `onboarding`,
    id: `onboarding_company_size_yn`,
    name: `Onboarding company size`,
    title: context => `Ask ${getUsersLabel(context, {})} how big their company is?`,
    description: () => `This is also useful for sales targeting`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `onboarding_company_size_n`,
    displayTest: context => context.teams_yn === `teams_y`,
    choices: [
      {
        id: `onboarding_company_size_n`,
      },
      {
        id: `onboarding_company_size_y`,
      },
    ],
  },
  {
    page: `onboarding`,
    id: `onboarding_welcome_yn`,
    name: `Onboarding welcome video`,
    title: context => `Show new ${getUsersLabel(context, {})} a welcome video?`,
    description: () => `This is a nice way to introduce them to the product, show them a familiar face, and make them feel at home`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `onboarding_welcome_n`,
    choices: [
      {
        id: `onboarding_welcome_n`,
      },
      {
        id: `onboarding_welcome_y`,
      },
    ],
  },
  {
    page: `onboarding`,
    id: `onboarding_checklist_yn`,
    name: `Onboarding checklist`,
    title: context => `Show ${getUsersLabel(context, {})} a checklist of things to help them get started?`,
    description: () => `This is a great way to guide people to engagement without getting in their way`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `onboarding_checklist_y`,
    choices: [
      {
        id: `onboarding_checklist_n`,
      },
      {
        id: `onboarding_checklist_y`,
      },
    ],
  },
  {
    page: `search`,
    id: `search_intro`,
    name: `Search intro`,
    title: () => `Global search`,
    description: context => <>
      <p>
        Adding a global search feature makes it so any user can find things quickly in your app. This is especially important for power {getUsersLabel(context, {})} etc.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `search`,
    id: `search_yn`,
    name: `Search`,
    title: () => `Include a search feature?`,
    description: () => `Highly recommended because you have lots of kinds of items with many items in each (500+)`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `search_y`,
    choices: [
      {
        id: `search_n`,
      },
      {
        id: `search_y`,
      },
    ],
  },
  {
    page: `notifications`,
    id: `notifications_intro`,
    name: `Notifications intro`,
    title: () => `Notifications`,
    description: () => <>
      <p>
        Notifications are a key lever for creating engagement.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `notifications`,
    id: `notifications_in_app_yn`,
    name: `Notifications platform`,
    title: () => `Show in-app notifications?`,
    description: context => `This will alert ${getUsersLabel(context, {})} of changes to their data since they last logged in`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `notifications_in_app_n`,
    choices: [
      {
        id: `notifications_in_app_n`,
      },
      {
        id: `notifications_in_app_y`,
      },
    ],
  },
  {
    page: `pricing`,
    id: `pricing_intro`,
    name: `Pricing intro`,
    title: () => `Pricing`,
    description: () => <>
      <p>
        Lots of apps charge users for use. Pricing isn't easy though...
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `pricing`,
    id: `charge_yn`,
    name: `Charging`,
    title: () => `Will you charge for your app?`,
    description: context => `If you will require at least some ${getUsersLabel(context, {})} to pay`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `charge_n`,
    choices: [
      {
        id: `charge_n`,
      },
      {
        id: `charge_y`,
      },
    ],
  },
  {
    page: `pricing`,
    id: `free_tier_yn`,
    name: `Free tier`,
    title: () => `Will you offer a free tier?`,
    QuestionComponent: QuestionToggle,
    description: context => `This will let low-usage ${getUsersLabel(context, {})} stay on your app forever`,
    yup_validation: yup.string().required(),
    default: () => `free_tier_n`,
    displayTest: context => context.charge_yn !== `charge_n`,
    choices: [
      {
        id: `free_tier_n`,
      },
      {
        id: `free_tier_y`,
      },
    ],
  },
  {
    page: `pricing`,
    id: `pricing_free_trial_yn`,
    name: `Free trial`,
    title: () => `Will you offer a free trial?`,
    description: () => `Free trials are good if you don't have a big sales team.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `pricing_free_trial_n`,
    displayTest: context => context.charge_yn !== `charge_n`,
    choices: [
      {
        id: `pricing_free_trial_n`,
      },
      {
        id: `pricing_free_trial_y`,
      },
    ],
  },
  {
    page: `email`,
    id: `email_intro`,
    name: `Email intro`,
    title: () => `Lifecycle emails`,
    description: () => <>
      <p>
        One of the best marketing tools you have at your disposal is your email list.
      </p>
      <p>
        Getting the right set of drip emails for your customers early is critical for building a passionate user base.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `email`,
    id: `email_types`,
    name: `Emails`,
    title: context => `What kind of emails will you send to ${getUsersLabel(context, {})}?`,
    description: () => `Lifecycle or "drip" emails from your app are one of the best ways to keep customers engaged.`,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => [`welcome_email`],
    choices: [
      {
        id: `welcome_email`,
        title: `Welcome email`,
        description: `A note from the founder and links to support docs`,
      },
      {
        id: `how_to`,
        title: `How-to email`,
        description: `Guide users to do a specific task`,
      },
      {
        id: `demo_invite`,
        title: `Demo invite`,
        description: `Host a live demo or sales workshop`,
      },
      {
        id: `case_studies`,
        title: `Case studies`,
        description: `Social proof that others like your product`,
      },
      {
        id: `trial_expiring`,
        title: `Trial expiring`,
        description: `Warn users that their free trial is about to expire`,
      },
      {
        id: `customer_welcome`,
        title: `Customer welcome`,
        description: `Thank you for new paying customers`,
      },
      {
        id: `usage_review`,
        title: `Usage review`,
        description: `Periodic update with usage stats`,
      },
      {
        id: `survey`,
        title: `Survey`,
        description: `Ask users to rate their experience`,
      },
    ],
  },
  {
    page: `support_pages`,
    id: `support_pages_intro`,
    name: `Support pages intro`,
    title: () => `Support pages`,
    description: () => <>
      <p>
        Support pages are one of the key ways to build customer loyalty and prevent leaky conversion funnels.
      </p>
      <p>
        They can even be one of your best marketing tools!
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `support_pages`,
    id: `support_pages_links`,
    name: `Support links`,
    title: () => `What kind of customer support will you offer?`,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => [],
    choices: [
      {
        id: `help`,
        title: `Support page link`,
        description: `Direct users to a support page or knowledge base`,
      },
      {
        id: `whats_new`,
        title: `New features list`,
        description: `Show users a list of recent changes to the app`,
      },
      {
        id: `feedback`,
        title: `Feedback form`,
        description: `Show a feedback form where users can send you comments`,
      },
    ],
  },
  {
    page: `security`,
    id: `security_intro`,
    name: `Security intro`,
    title: () => `Security`,
    description: context => <>
      <p>
        To save data in your app, {getUsersLabel(context, {})} will have to create accounts and be able to sign in.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `security`,
    id: `oauth_methods`,
    name: `Oauth providers`,
    title: context => `Besides email and password, how else will you let ${getUsersLabel(context, {})} log in?`,
    description: () => <>
      <p>
        It's always a good idea to let people create accounts with their email and a password, but other methods can make signing in faster and more secure.
      </p>
    </>,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => [],
    choices: [
      {
        id: `google`,
        title: `Google`,
      },
      {
        id: `facebook`,
        title: `Facebook`,
      },
      {
        id: `apple`,
        title: `Apple`,
      },
      {
        id: `twitter`,
        title: `Twitter`,
      },
      {
        id: `sms`,
        title: `Phone/SMS`,
      },
    ],
  },
  {
    page: `security`,
    id: `two_factor_yn`,
    name: `Two factor auth`,
    title: () => `Add two-factor authentication?`,
    description: context => `(For ${getUsersLabel(context, {})} who sign in with email.) This makes your app more secure by requiring a one-time code sent to their phone. Good if you store very sensitive data in your app.`,
    QuestionComponent: QuestionToggle,
    default: () => `two_factor_n`,
    choices: [
      {
        id: `two_factor_n`,
      },
      {
        id: `two_factor_y`,
      },
    ],
  },
  {
    page: `security`,
    id: `email_confirm_yn`,
    name: `Email confirmation`,
    title: context => `Ask ${getUsersLabel(context, {})} to confirm their email?`,
    description: () => `A good best-practice for extra security. It's also a good way to prevent your marketing emails from going to spam folders.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `email_confirm_y`,
    comingSoon: true,
    choices: [
      {
        id: `email_confirm_n`,
      },
      {
        id: `email_confirm_y`,
      },
    ],
  },
  {
    page: `localization`,
    id: `localization_intro`,
    name: `Localization intro`,
    title: () => `Localization`,
    description: () => <>
      <p>
        One of the beautiful things about the internet is that you can offer your software to anyone in the world.
      </p>
      <p>
        Going international is easier said than done, though. There are a few critical considerations to make when selling outside of the USA.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `localization`,
    id: `language_yn`,
    name: `Language`,
    title: context => `Allow ${getUsersLabel(context, {})} to set their language?`,
    description: () => `Useful if your app serves a diverse user base`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `language_n`,
    choices: [
      {
        id: `language_n`,
      },
      {
        id: `language_y`,
      },
    ],
  },
  {
    page: `localization`,
    id: `time_zone_yn`,
    name: `Time zone`,
    title: context => `Allow ${getUsersLabel(context, {})} to set their time zone?`,
    description: () => `Useful if your app will schedule events etc`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `time_zone_n`,
    choices: [
      {
        id: `time_zone_n`,
      },
      {
        id: `time_zone_y`,
      },
    ],
  },
  {
    page: `localization`,
    id: `currency_yn`,
    name: `Currency`,
    title: context => `Let ${getUsersLabel(context, {})} choose their default currency?`,
    description: () => `Useful if your app manages payments of finances in multiple locales`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `currency_n`,
    choices: [
      {
        id: `currency_n`,
      },
      {
        id: `currency_y`,
      },
    ],
  },
  {
    page: `localization`,
    id: `week_start_yn`,
    name: `Week start`,
    title: context => `Let ${getUsersLabel(context, {})} change the first day of the week?`,
    description: () => `Many non-european countries' calendars show the week starting on Sunday. In European calendars, weeks usually start on Monday.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `week_start_n`,
    choices: [
      {
        id: `week_start_n`,
      },
      {
        id: `week_start_y`,
      },
    ],
  },
  {
    page: `legal`,
    id: `legal_intro`,
    name: `Legal intro`,
    title: () => `Legal stuff`,
    description: () => <>
      <p>
        Legal stuff isn't fun, but it's necessary to protect your business (and to protect your customers' data).
      </p>
      <p style={{fontStyle: 'italic'}}>
        NOTE: We are not lawyers and this is not legal advice!
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `legal`,
    id: `legal_name`,
    name: `Legal name`,
    title: () => `What's your company's legal name?`,
    description: () => `This will be used in copyright text and legal pages.`,
    example: `Example: "Global Enterprises LLC"`,
    QuestionComponent: QuestionBigText,
    yup_validation: yup.string().required(),
    default: context => `${context.app_name || `Acme`}, Inc`,
  },
  {
    page: `legal`,
    id: `legal_links`,
    name: `Legal links`,
    title: () => `What kinds of legal links do you need to include?`,
    description: () => `These are common to most apps`,
    QuestionComponent: QuestionCheckboxes,
    yup_validation: yup.array().of(yup.string()),
    default: () => [`terms`, `privacy`],
    choices: [
      {
        id: `terms`,
        title: `Terms of service`,
        description: `Highly recommended for all apps`,
      },
      {
        id: `privacy`,
        title: `Privacy policy`,
        description: `Highly recommended for all apps`,
      },
      {
        id: `dmca`,
        title: `DMCA`,
        description: `For apps that show publicly available content/media`,
      },
      {
        id: `gdpr`,
        title: `GDPR`,
        description: `For companies who operate in Europe`,
      },
      {
        id: `accessibility`,
        title: `Accessibility statement`,
        description: `For companies with certain compliance requirements`,
      },
    ],
  },
  {
    page: `navigation_type`,
    id: `layout_intro`,
    name: `Layout intro`,
    title: () => `Layout`,
    description: () => <>
      <p>
        Depending on how many pages, you can do navigation very differently
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `navigation_type`,
    id: `navigation_type_picker`,
    name: `Navigation type`,
    title: () => `What style of navigation works best for your app?`,
    QuestionComponent: QuestionRadio,
    yup_validation: yup.string().required(),
    default: () => `vertical`,
    choices: [
      {
        id: `vertical`,
        title: `Vertical/side navigation`,
        description: `Lots of room for pages, feels like a "web app"`,
      },
      {
        id: `horizontal`,
        title: `Horizontal/top navigation`,
        description: `Simple and clean, feels like a "website"`,
      },
    ],
  },
  {
    page: `colors`,
    id: `colors_intro`,
    name: `Colors intro`,
    title: () => `Colors`,
    description: () => <>
      <p>
        Colors are one of the biggest ways you can express your brand
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `colors`,
    id: `color_primary`,
    name: `Brand color`,
    title: () => `What is your main brand color?`,
    description: () => `This will help us add a little bit of branded customization to the app`,
    QuestionComponent: QuestionSelect,
    yup_validation: yup.string().required(),
    default: context => context.wizard_color || `blue`,
    // TODO - keep in sync with wizard_color!!
    choices: [
      {
        id: `gray`,
        title: `Gray`,
        description: `Minimal, sophisticated`,
        example: `Example: Apple, Uber`,
      },
      {
        id: `red`,
        title: `Red`,
        description: `Bold, passionate`,
        example: `Example: Netflix, Youtube, Doordash`,
      },
      {
        id: `pink`,
        title: `Pink`,
        description: `Odd, iconoclastic`,
        example: `Example: Lyft, T-mobile`,
      },
      {
        id: `purple`,
        title: `Purple`,
        description: `Extravagant, royal`,
        example: `Example: Twitch, Roku`,
      },
      {
        id: `indigo`,
        title: `Indigo`,
        description: `Corporate, stable`,
        example: `Example: LinkedIn`,
      },
      {
        id: `blue`,
        title: `Blue`,
        description: `Classic, inoffensive`,
        example: `Example: Facebook, Venmo`,
      },
      {
        id: `aqua`,
        title: `Aqua`,
        description: `Serene, relaxing`,
        example: `Example: Tiffany, Canva`,
      },
      {
        id: `green`,
        title: `Green`,
        description: `Profitable, natural`,
        example: `Example: Whole Foods, John Deere`,
      },
      {
        id: `lime`,
        title: `Lime`,
        description: `Funky, flavorful`,
        example: `Example: Spotify, HelloFresh`,
      },
      {
        id: `yellow`,
        title: `Yellow`,
        description: `Friendly, simple`,
        example: `Example: Snap, Bumble`,
      },
      {
        id: `orange`,
        title: `Orange`,
        description: `Fresh, bright`,
        example: `Example: Soundcloud, Firefox`,
      },
    ],
  },
  {
    page: `colors`,
    id: `color_boldness`,
    name: `Color boldness`,
    title: () => `Use bold colors?`,
    description: () => `This is another way to give your brand personality and make it stand out. Bold colors can be a little intense though.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `color_clean`,
    choices: [
      {
        id: `color_clean`,
      },
      {
        id: `color_bold`,
      },
    ],
  },
  {
    page: `colors`,
    id: `color_gray_bg`,
    name: `Gray background`,
    title: () => `Use a gray background?`,
    description: () => `This can make things feel a little more organized, but maybe a little less simple and clean.`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `color_gray_n`,
    choices: [
      {
        id: `color_gray_n`,
      },
      {
        id: `color_gray_y`,
      },
    ],
  },
  {
    page: `colors`,
    id: `color_dark_mode`,
    name: `Dark mode`,
    title: () => `Add a dark mode option?`,
    description: context => `Especially useful for advanced users or ${getUsersLabel(context, {})} who use the app at night`,
    QuestionComponent: QuestionToggle,
    yup_validation: yup.string().required(),
    default: () => `color_dark_mode_n`,
    comingSoon: true,
    choices: [
      {
        id: `color_dark_mode_n`,
      },
      {
        id: `color_dark_mode_y`,
      },
    ],
  },
  {
    page: `feel`,
    id: `feel_intro`,
    name: `Feel intro`,
    title: () => `Feel`,
    description: () => <>
      <p>
        These are a few more things that can help you define your brand.
      </p>
    </>,
    QuestionComponent: QuestionIntro,
  },
  {
    page: `feel`,
    id: `font_display`,
    name: `Fonts`,
    title: () => `What kind of font should we use for headings?`,
    description: () => <>
      <p>
      Choosing a custom heading font can give your app a little extra style and personality
      </p>
      <p>
        All these fonts are available for both web and mobile via Google Fonts (free) or Adobe Typekit ($5/month)
      </p>
    </>,
    QuestionComponent: QuestionRadio,
    yup_validation: yup.string().required(),
    default: () => `simple`,
    choices: [
      {
        id: `simple`,
        title: `Simple`,
        description: `The normal font`,
      },
      {
        id: `owners`,
        title: `Wide and bold`,
        description: `Youthful, aggressive, funky`,
      },
      // {
      //   id: `garamond`,
      //   title: `Traditional`,
      //   description: `Solid, old, reliable, academic`,
      // },
      {
        id: `clarendon`,
        title: `Slab`,
        description: `Offbeat, historic, charming`,
      },
    ],
  },
  {
    page: `feel`,
    id: `ui_roundedness`,
    name: `Roundedness`,
    title: () => `Rounded corners`,
    description: () => `Most apps have subtly rounded corners. More rounded corners make the app feel friendlier; less rounding feels more "rigid"`,
    QuestionComponent: QuestionRadio,
    yup_validation: yup.string().required(),
    default: () => `normal_rounded`,
    choices: [
      {
        id: `not_rounded`,
        title: `Not rounded at all`,
        description: `Technical, strong, rigid`,
      },
      {
        id: `normal_rounded`,
        title: `Normal roundedness`,
        description: `Clean, modern, professional`,
      },
      {
        id: `very_rounded`,
        title: `Very rounded`,
        description: `Youthful, goofy, funny, playful`,
      },
    ],
  },
]

export default Questions
