import styled from 'styled-components/macro'

import ArrowDown from '../../svg/previews/arrow_down.svg'
import AppleLogo from '../../svg/oauth/apple.svg'
import GoogleLogo from '../../svg/oauth/google.svg'
import FacebookLogo from '../../svg/oauth/facebook.svg'
import PhoneLogo from '../../svg/oauth/phone.svg'
import TwitterLogo from '../../svg/oauth/twitter.svg'

import {
  PageFlowWrapper,
  PageFlowArrow,
  // PageFlowPoint,
  Button,
} from '../../styles/Previews'

const PreviewAuth = ({ context }) => {
  const {
    oauth_methods,
    two_factor_yn,
  } = context

  const email = true

  const google = oauth_methods && oauth_methods.includes('google')
  const facebook = oauth_methods && oauth_methods.includes('facebook')
  const apple = oauth_methods && oauth_methods.includes('apple')
  const twitter = oauth_methods && oauth_methods.includes('twitter')
  const sms = oauth_methods && oauth_methods.includes('sms')

  return <PageFlowWrapper>

    <AuthWrapper>
      <LoginHeader>Log in</LoginHeader>

      {email && <div>
        <LoginLabel>Email</LoginLabel>
        <LoginInput placeholder="your@email.com" readonly />
        <LoginLabel>Password</LoginLabel>
        <LoginInput placeholder="···········" readonly />
        <Button>Sign in</Button>
        <AccountText>Forgot your password?</AccountText>
      </div>}

      {email && oauth_methods && oauth_methods.length > 0 &&
        <LoginHr />
      }

      {google &&
        <AuthButton>
          <AuthButtonIcon src={GoogleLogo} />
          <span>Continue with Google</span>
        </AuthButton>
      }
      {facebook &&
        <AuthButton>
          <AuthButtonIcon src={FacebookLogo} />
          <span>Continue with Facebook</span>
        </AuthButton>
      }
      {apple &&
        <AuthButton>
          <AuthButtonIcon src={AppleLogo} />
          <span>Continue with Apple</span>
        </AuthButton>
      }
      {twitter &&
        <AuthButton>
          <AuthButtonIcon src={TwitterLogo} />
          <span>Continue with Twitter</span>
        </AuthButton>
      }
      {sms &&
        <AuthButton>
          <AuthButtonIcon src={PhoneLogo} />
          <span>Text me a code</span>
        </AuthButton>
      }
    </AuthWrapper>

    {two_factor_yn === 'two_factor_y' && <>
      <PageFlowArrow src={ArrowDown} />
      <AuthWrapper>
        <LoginHeader>Two-step verification</LoginHeader>
        <LoginP>
          We sent a code to your phone number ending in
          {' '}
          <strong>1234</strong>
        </LoginP>
        <LoginLabel>Enter code</LoginLabel>
        <TwoFaInputWrapper>
          <TwoFaInput />
          <TwoFaInput />
          <TwoFaInput />
          <div />
          <TwoFaInput />
          <TwoFaInput />
          <TwoFaInput />
        </TwoFaInputWrapper>
        <Button>Continue</Button>
        <AccountText>Need help?</AccountText>
      </AuthWrapper>
    </>}
  </PageFlowWrapper>
}

const AuthWrapper = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 4px;
  box-shadow: var(--shadow-elevation-medium);
  width: 360px;

  *:last-child {
    margin-bottom: 0;
  }
`
const LoginHeader = styled.div`
  font-size: var(--l);
  font-weight: bold;
  margin: 0 0 1rem;
`
const LoginP = styled.p`
  margin: 0 0 2rem;
`
const LoginLabel = styled.label`
  display: block;
  font-size: var(--s);
  font-weight: 500;
  margin: 0 0 .5rem;
`
const AccountText = styled.label`
  display: block;
  font-size: var(--xs);
  text-align: center;
  color: var(--text-secondary);
  margin: 0 0 .5rem;
`
const LoginInput = styled.div`
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 1rem;
  margin: 0 0 1rem;
`
const LoginHr = styled.hr`
  border: none;
  height: 1px;
  background: var(--hairline);
  margin: 1rem 0;
`
const AuthButton = styled.div`
  margin: 0 0 .5rem;
  width: 100%;

  padding: .5em 1em;

  text-align: center;
  border-radius: 4px;
  font-weight: 500;
  line-height: 1;

  background: white;
  color: ${p => p.theme.textPrimary};
  box-shadow: 0 2px 4px var(--shadow);
  border: 1px solid var(--input-border);

  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 1rem;
  align-content: center;
  justify-content: center;

  &:last-child {
    margin-bottom: 0;
  }
`
const AuthButtonIcon = styled.img`
  width: 20px;
  height: 20px;
  margin: 0;
  display: block;
`

// TWO FACTOR

const TwoFaInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr .5rem 1fr 1fr 1fr;
  grid-column-gap: .25rem;
  margin: 0 0 2rem;
`
const TwoFaInput = styled.div`
  /* width: 2rem; */
  height: 3rem;
  border: 1px solid var(--input-border);
  border-radius: 4px;
  box-shadow: inset 0 2px 4px #eee;
`

export default PreviewAuth
