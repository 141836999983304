import styled from 'styled-components/macro'

export const Select = styled.select`
  vertical-align: top;
  display: block;
  position: relative;
  padding: .5rem 1rem .5rem .5rem;

  background: white;
  border: 1px solid var(--input-border);
  border-radius: 4px;
  
  color: var(--brand-primary);
  font-size: var(--m);
  font-family: inherit;
  font-weight: 500;
  letter-spacing: 0.015rem;
  line-height: 1;

  cursor: ${p => p.working ? 'auto' : p.disabled ? 'auto' : 'pointer'};
  opacity: ${p => p.working ? .5 : .95};
  user-select: none;
  box-shadow: 0 2px 4px var(--shadow);

  transition: opacity .1s ease;

  &:hover {
    opacity: ${p => p.working ? .5 : 1};
  }
  outline: none;
  &:focus {
    box-shadow: inset 0 0 0 2px var(--outline);
  }
  &:disabled {
    opacity: .3;
    cursor: auto;
  }
  &:before {
    display: ${p => p.working ? 'block' : 'none'};
    content: '';
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 14px;
    height: 14px;
    margin-top: -8px;
    margin-left: -8px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: var(--white);
    animation: spinner .6s linear infinite;
  }

  @media(max-width: 600px) {
    display: block;
    width: 100%;
    margin: 0 0 1rem;
  }
`
export const Input = styled.input`
  display: block;
  padding: ${p => p.$large ? '1rem' : p.$small ? '.5rem' : '.66rem'};
  margin: 0;

  color: var(--brand-primary);
  font-family: inherit;
  font-size: ${p => p.$small ? 'var(--s)' : p.$large ? 'var(--m)' : 'var(--m)'};
  line-height: 1;

  background: white;
  border: 1px solid var(--input-border);
  border-radius: 4px;
  /* box-shadow: inset 0 2px 4px var(--shadow); */

  cursor: ${p => p.disabled ? 'auto' : 'pointer'};
  transition: opacity .1s ease;
  outline: none;

  &:focus {
    box-shadow: inset 0 0 0 2px var(--outline);
  }

  &:disabled {
    opacity: .3;
    cursor: auto;
  }
`
export const FormField = styled.div`
  margin: 0 0 1rem;
`
export const Label = styled.label`
  margin: 0 0 1rem;
  font-weight: 500;
  font-size: var(--s);
`
