import { useForm } from 'react-hook-form'

import {
  Select,
  FormField,
  Input,
  Label,
} from '../../../styles/Forms'
import {
  Button,
} from '../../../styles/Buttons'
import AttributeTypes from '../../../data/attribute_types'

const AttributeForm = ({
  item,
  save,
  close,
}) => {

  const {
    register,
    handleSubmit,
  } = useForm({
    defaultValues: item,
  })

  return <form onSubmit={handleSubmit(save)}>
    <FormField>
      <Label>Attribute name</Label>
      <Input
        type="text"
        autoFocus
        required
        {...register('title')}
      />
    </FormField>

    <FormField>
      <Label>What type of data is this?</Label>
      <Select
        required
        {...register('type')}
      >
        { AttributeTypes.map(at => {
          return <option key={at.id} value={at.id}>{at.title}</option>
        })}
      </Select>
    </FormField>

    <Button $secondary onClick={close}>Cancel</Button>
    {' '}
    <Button type="submit">Save changes</Button>
  </form>
}

export default AttributeForm
