import pluralize from 'pluralize'

// https://stackoverflow.com/a/1026087
const capitalizeFirstLetter = ([ first, ...rest ], locale = navigator.language) => {
  return first && first.toLocaleUpperCase(locale) + rest.join('')
}
const unCapitalizeFirstLetter = ([ first, ...rest ], locale = navigator.language) => {
  return first && first.toLocaleLowerCase(locale) + rest.join('')
}

function getUsersLabel(context, { singular, capital }) {
  const name = context.users_label || 'users'
  const singularName = singular ? pluralize.singular(name) : name
  return capital ? capitalizeFirstLetter(singularName) : unCapitalizeFirstLetter(singularName)
}

export default getUsersLabel
