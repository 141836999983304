import { useState, createContext} from 'react'

import Router from './Router'
import Purchase from './Purchase'

export const AppContext = createContext({})

const App = () => {

  
  const [ formData, setFormData ] = useState({
    // need to have a default array here because some things expect to map through formData collections
    // esp Panel.js on load
    collections: [],
  })
  const [ isPurchasing, setIsPurchasing ] = useState()

  const saveFormData = data => {
    setFormData({
      ...formData,
      ...data,
    })
  }

  return <AppContext.Provider value={{formData, saveFormData, isPurchasing, setIsPurchasing }}>
    {isPurchasing && <Purchase /> }

    <Router />
  </AppContext.Provider>
}

export default App
