import {
  AppNavMain,
  NavLogo,
  NavItem,
  NavIcon,
  NavText,
} from '../../../styles/Previews'
import { NavItems } from '../data/navItems'

const NavMain = ({ selectedPage, context }) => {
  const {
    collections,
    app_name,
    navigation_type_picker,
    currentCollection,
  } = context
  
  const allItems = NavItems
    .filter(i => i.level === 'primary_nav')
    .filter(i => i.displayTest(context))

  const aboveItems = allItems && allItems.filter(i => i.emphasized)
  const belowItems = allItems && allItems.filter(i => !i.emphasized)

  const activeZone = (aboveItems && aboveItems.length > 0) ? 'above' :
                     (collections && collections.length > 0) ? 'collections' :
                     'below'

  const realSelectedPage = selectedPage && allItems.map(i => i.id).includes(selectedPage) && selectedPage

  return <AppNavMain>
    <NavLogo>{app_name || 'MyApp'}</NavLogo>

    {aboveItems && aboveItems.map( (item, index) => {
      const { id, icon, label } = item
      const isSelected = realSelectedPage ? realSelectedPage === id : (index === 0 && !currentCollection && activeZone === 'above')
      return <NavItem key={id} className={isSelected && 'active'}>
        {navigation_type_picker !== 'horizontal' && <NavIcon>
          <span className="iconify" data-icon={`mdi-${icon || 'square'}`} />
        </NavIcon> }
        <NavText>{label || 'Page'}</NavText>
      </NavItem>
    })}

    {
      aboveItems &&
      aboveItems.length > 1 &&
      <br />
    }

    {collections && collections.map( (collection, index) => {
      const { id, collection_icon, collection_name_plural } = collection
      const isSelected = realSelectedPage ? realSelectedPage === id : ((index === 0 && activeZone === 'collections') || currentCollection?.id === id)
      return <NavItem key={id} className={isSelected && 'active'}>
        {navigation_type_picker !== 'horizontal' && <NavIcon>
          <span className="iconify" data-icon={`mdi-${collection_icon || 'square'}`} />
        </NavIcon> }
        <NavText>{collection_name_plural || 'Page'}</NavText>
      </NavItem>
    })}

    {
      belowItems &&
      belowItems.length > 0 &&
      <br />
    }

    {belowItems && belowItems.map( (item, index) => {
      const { id, icon, label } = item
      const isSelected = realSelectedPage ? realSelectedPage === id : (index === 0 && !currentCollection && activeZone === 'below')
      return <NavItem key={id} className={isSelected && 'active'}>
        {navigation_type_picker !== 'horizontal' && <NavIcon>
          <span className="iconify" data-icon={`mdi-${icon || 'square'}`} />
        </NavIcon> }
        <NavText>{label || 'Page'}</NavText>
      </NavItem>
    })}

  </AppNavMain>
}

export default NavMain
