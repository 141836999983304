const PageGroups = {
  users: {
    title: `Users`,
    icon: `account-outline`,
  },
  other_pages: {
    title: `Other pages`,
    icon: `plus-box-outline`,
  },
  extras: {
    title: `Extras`,
    // icon: `application-cog-outline`,
    icon: `tune-variant`,
  },
  style: {
    title: `Style`,
    icon: `palette-outline`,
  },
}

export default PageGroups
