import styled from 'styled-components/macro'

import { Actions } from '../data/actions'

const ActionsMenu = ({ context }) => {

  const items = Actions
    .filter(a => a.displayTest(context))

  return <PreviewActionsMenu>
    {items.map(action => {
      const { id, icon, label, destructive } = action
      return <PreviewActionsItem key={id} $destructive={destructive}>
        <PreviewActionsItemIcon>
          <span className="iconify" data-icon={`mdi-${icon || 'square'}`} />
        </PreviewActionsItemIcon>
        <span>{label}</span>
      </PreviewActionsItem>
    })}
  </PreviewActionsMenu>
}

const PreviewActionsMenu = styled.div`
  width: 200px;
  border-radius: 4px;
  background: white;
  box-shadow: var(--shadow-elevation-medium);
  color: var(--text-primary);
  padding: .5rem;
`
const PreviewActionsItem = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: .75rem;
  padding: .75rem;
  align-items: center;
  color: ${p => p.$destructive ? 'var(--destructive)' : 'var(--text-primary)'};
  font-weight: 500;
  font-size: var(--s);

  &:last-child {
    border-bottom: none;
  }
`
const PreviewActionsItemIcon = styled.div`
  opacity: .75;

  svg {
    display: block;
    width: 18px;
    height: 18px;
  }
`

export default ActionsMenu
