import * as Accordion from '@radix-ui/react-accordion';
import styled from 'styled-components/macro'
import { AnimatePresence, motion } from 'framer-motion'

export const AccordionRoot = styled(Accordion.Root)`
`
export const AccordionItem = styled(Accordion.Item)`
`
export const AccordionHeader = styled(Accordion.Header)`
`
export const AccordionTrigger = styled(Accordion.Trigger)`
`
export const AccordionContent = styled(Accordion.Content)`
  overflow: hidden;
`

export const AccordionHead = ({ children }) => {
  return <AccordionHeader as="div">
    <AccordionTrigger asChild={true}>
      {children}
    </AccordionTrigger>
  </AccordionHeader>
}

// https://codesandbox.io/s/njdoo?file=/src/App.js:499-900
// https://codesandbox.io/s/framer-motion-accordion-qx958?file=/src/Example.tsx:602-933
export const AccordionBody = ({ isShown, children }) => {
  return <AnimatePresence>
    {isShown && (
      <Accordion.Content asChild forceMount>
        <motion.div
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: {
              opacity: 1,
              height: "auto",
              overflow: "hidden",
            },
            collapsed: {
              opacity: 0,
              height: 0,
              overflow: "hidden",
            }
          }}
          transition={{
            duration: 0.3,
            // ease: [0.04, 0.62, 0.23, 0.98],
          }}
        >
          {children}
        </motion.div>
      </Accordion.Content>
    )}
  </AnimatePresence>
}