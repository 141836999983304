import NavSecondary from './components/NavSecondary'
import NavMain from './components/NavMain'
import {
  AppMain,
  ContentBox,
  AppBody,
  AppNav,
} from '../../styles/Previews'

const PreviewNotifications = ({ context }) => {
  return <AppBody>
    <AppNav>
      <NavMain context={context} />
      <NavSecondary context={context} />
    </AppNav>

    <AppMain>
      <ContentBox />
      <ContentBox />
      <ContentBox />
    </AppMain>
  </AppBody>
}

export default PreviewNotifications
