import { ThemeProvider } from 'styled-components/macro'
import { Themes } from '../../styles/Themes'

const Preview = ({
  currentPage,
  formData,
  currentCollectionId,
  isDataPage,
  forceComponent,
}) => {

  const context = {
    ...formData,
    currentCollectionId,
    currentCollection: formData.collections.find(c => c.id === currentCollectionId),
    isDataPage,
  }

  const PreviewComponent = forceComponent || currentPage?.PreviewComponent

  // TODO - move these to separate Theme files like colors is? Or one big giant theme config file? Or bring colors in this file?
  const fontThemes = {
    simple: {
      fontHeading: 'Arial, sans-serif',
    },
    owners: {
      fontHeading: `'Krona One', sans-serif`,
    },
    garamond: {
      fontHeading: `'Lora', serif`,
    },
    clarendon: {
      fontHeading: `'Domine', serif`,
    },
  }

  const roundednessThemes = {
    not_rounded: {
      buttonRadius: `0px`,
      cardRadius: `0px`,
    },
    normal_rounded: {
      buttonRadius: `4px`,
      cardRadius: `6px`,
    },
    very_rounded: {
      buttonRadius: `100px`,
      cardRadius: `12px`,
    },
  }

  const grayBgThemes = {
    color_gray_y: {
      bodyBg: `#fcfcfc`,
      cardShadow: `0 0 0 1px #eee, 0 2px 4px #eee`,
    },
    color_gray_n: {
      bodyBg: `white`,
      cardShadow: `0 0 0 1px #ddd`,
    },
  }

  // TODO - keep in sync with defaults in questions.js
  const colorTheme = Themes[context.color_primary] || Themes['blue']
  const fontTheme = fontThemes[context.font_display] || fontThemes['simple']
  const roundednessTheme = roundednessThemes[context.ui_roundedness] || roundednessThemes['normal_rounded']
  const grayBgTheme = grayBgThemes[context.color_gray_bg] || grayBgThemes['color_gray_y']

  const theme = {
    ...colorTheme,
    ...fontTheme,
    ...roundednessTheme,
    boldColor: context.color_boldness === 'color_bold',
    ...grayBgTheme,
    navType: context.navigation_type_picker === 'horizontal' ? 'horizontal' : 'vertical',
    standalone: !!forceComponent,
  }
  
  if (!PreviewComponent) {
    return null
  }
  return <ThemeProvider theme={theme}>
    <PreviewComponent context={context} />
  </ThemeProvider>
}

export default Preview
