import styled from 'styled-components/macro'

const PreviewAttrTags = ({ $large }) => {
  return <>
    <Tag>Lorem</Tag>
    <Tag>Ipsum</Tag>
    <Tag>Dolor</Tag>
  </>
}

const Tag = styled.div`
  display: inline-block;
  border-radius: 6px;
  background: #eee;
  font-size: var(--s);
  padding: 3px 4px;
  line-height: 1;
  margin: 0 .25rem .5rem 0;
`

export default PreviewAttrTags
