import {
  QuestionWrapper,
  QuestionTitle,
  QuestionDescription,
  Example,
  Option,
  OptionTitle,
  OptionDescription,
  QuestionError,
  Badge,
} from '../../styles/Questions'

const Checkboxes = ({
  question,
  register,
  errors,
  context,
}) => {

  const { id, title, description, example, choices, comingSoon } = question

  return <QuestionWrapper>
    <QuestionTitle>
      { title(context) }
      { comingSoon && <Badge>Coming soon</Badge>}
    </QuestionTitle>
    {description && <QuestionDescription>{ description(context) }</QuestionDescription> }
    {example && <Example>{ example }</Example> }
    <div>
      { choices && choices.map(choice => {
        return <Option key={choice.id} disabled={choice.disabled}>
          <input
            name={id}
            type="checkbox"
            value={choice.id}
            disabled={choice.disabled || comingSoon}
            {...register(id)}
          />
          <div>
            <OptionTitle>{choice.title}</OptionTitle>
            <OptionDescription>{choice.description}</OptionDescription>
            {choice.example && <Example>{ choice.example }</Example> }
          </div>
        </Option>
      })}
    </div>
    {errors[id] && <QuestionError>This field is required</QuestionError>}
  </QuestionWrapper>
}

export default Checkboxes
