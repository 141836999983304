export const Actions = [
  {
    id: `preview`,
    label: `Preview`,
    icon: `eye`,
    destructive: false,
    displayTest: context => context.currentCollection?.item_actions?.includes('preview'),
  },
  {
    id: `rename`,
    label: `Rename`,
    icon: `account-edit-outline`,
    destructive: false,
    displayTest: context => context.currentCollection?.editing_yn !== 'editing_n' && context.currentCollection?.item_actions?.includes('rename'),
  },
  {
    id: `edit`,
    label: `Edit`,
    icon: `pencil`,
    destructive: false,
    displayTest: context => context.currentCollection?.editing_yn !== 'editing_n',
  },
  {
    id: `duplicate`,
    label: `Duplicate`,
    icon: `plus-circle-multiple-outline`,
    destructive: false,
    displayTest: context => context.currentCollection?.editing_yn !== 'editing_n' && context.currentCollection?.item_actions?.includes('duplicate'),
  },
  {
    id: `message`,
    label: `Message`,
    icon: `message`,
    destructive: false,
    displayTest: context => {
      return context.messaging_yn === 'messaging_y'
    },
  },
  {
    id: `favorite`,
    label: `Favorite`,
    icon: `heart`,
    destructive: false,
    displayTest: context => context.currentCollection?.favorite_yn === 'favorite_y',
  },
  {
    id: `share`,
    label: `Share`,
    icon: `link`,
    destructive: false,
    displayTest: context => context.currentCollection?.item_actions?.includes('share'),
  },
  {
    id: `print`,
    label: `Print`,
    icon: `printer`,
    destructive: false,
    displayTest: context => context.currentCollection?.item_actions?.includes('print'),
  },
  {
    id: `follow`,
    label: `Follow`,
    icon: `add`,
    destructive: false,
    displayTest: context => {
      return context.follow_users_yn === 'follow_users_y'
    },
  },
  {
    id: `download`,
    label: `Download`,
    icon: `download`,
    destructive: false,
    displayTest: context => context.currentCollection?.item_actions?.includes('download'),
  },
  {
    id: `export`,
    label: `Export`,
    icon: `export`,
    destructive: false,
    displayTest: context => context.currentCollection?.item_actions?.includes('export'),
  },
  {
    id: `report`,
    label: `Report`,
    icon: `flag`,
    destructive: false,
    displayTest: context => context.currentCollection?.item_actions?.includes('report'),
  },
  {
    id: `archive`,
    label: `Archive`,
    icon: `archive`,
    destructive: false,
    displayTest: context => context.currentCollection?.archive_yn === 'archive_y',
  },
  {
    id: `delete`,
    label: `Delete`,
    icon: `trash`,
    destructive: true,
    displayTest: context => context.currentCollection?.editing_yn !== 'editing_n',
  },
]

