import {
  AppNavSecondaryWrapper,
  NavItem,
  NavIcon,
  NavText,
  PopupAvatar,
  NavAccount,
} from '../../../styles/Previews'
import { NavItems } from '../data/navItems'

const NavSecondary = ({ context }) => {
  const {
    navigation_type_picker,
    search_yn,
  } = context

  const items = NavItems
    .filter(i => i.level === 'secondary_nav')
    .filter(i => i.emphasized)
    .filter(i => i.displayTest(context))


  return <AppNavSecondaryWrapper>
    {
      search_yn === 'search_y' &&
      navigation_type_picker === 'horizontal' &&
      <NavItem key='search'>
        <NavIcon>
          <span className="iconify" data-icon={`mdi-search`} />
        </NavIcon>
      </NavItem>
    }

    {items && items.map( item => {
      const { id, icon, label } = item
      return <NavItem key={id}>
        <NavIcon>
          <span className="iconify" data-icon={`mdi-${icon || 'square'}`} />
        </NavIcon>
        {navigation_type_picker !== 'horizontal' && <NavText>{label || 'Page'}</NavText>}
      </NavItem>
    })}

    {navigation_type_picker === 'horizontal' && 
      <NavAccount>
        <PopupAvatar alt="profile pic" src="https://www.placebear.com/100/100" size={30} />
      </NavAccount>
    }
  </AppNavSecondaryWrapper>
}

export default NavSecondary
