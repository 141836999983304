import {
  ContentBox,
} from '../../../styles/Previews'

const PreviewAttrUnknown = ({ $large }) => {
  if ($large) {
    return <>
      <ContentBox $small />
      <ContentBox $small />
      <ContentBox $small />
    </>
  }
  return '??????'
}

export default PreviewAttrUnknown
